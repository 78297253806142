const NAVIGATION = {
    SELECT: {
        event: 'navigation',
        eventProperties: {
            description: 'User click on app navigation',
            selection: '',
        },
    },
    HELP_CENTER: {
        event: 'Navigation - Help Center',
        eventProperties: {
            description: 'User click on help center',
            selection: '',
        },
    },
    SELECTIONS: {
        NAV_LOGO: 'navlogo',
    },
};

export default NAVIGATION;
