import axios from 'axios';
import Qs from 'qs';

/**
 * API call to fetch aca questionnaire information
 *
 * @method fetchQuestionnaireInfo
 * @return {Promise}
 */
export const fetchQuestionnaireInfoAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires?name=aca`,
    });
};

/**
 * API call to fetch aca questionnaire status
 *
 * @method fetchQuestionnaireStatus
 * @param   {number}    id
 * @param   {number}    companyId
 * @return {Promise}
 */
export const fetchQuestionnaireStatusAPI = ({ id, ...params }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires/${id}/status`,
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * API call to set aca questionnaire status
 *
 * @method setQuestionnaireStatus
 * @param   {number}    id
 * @param   {Object}    data
 * @return {Promise}
 */
export const setQuestionnaireStatusAPI = ({ questionnaireId, data }) => {
    return axios({
        method: 'POST',
        url: `/v2/questionnaires/${questionnaireId}/status`,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to update aca questionnaire status
 *
 * @method updateQuestionnaireStatus
 * @param   {number}    questionnaireId
 * @param   {number}    statusId
 * @param   {Object}    data
 * @return {Promise}
 */
export const updateQuestionnaireStatusAPI = ({ questionnaireId, statusId, data }) => {
    return axios({
        method: 'PUT',
        url: `/v2/questionnaires/${questionnaireId}/status/${statusId}`,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to fetch all questions
 *
 * @method fetchQuestionsAPI
 * @param   {number}    id
 * @return {Promise}
 */
export const fetchQuestionsAPI = ({ questionnaireId }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires/${questionnaireId}/questions`,
    });
};

/**
 * API call to fetch list of all answers
 *
 * @method fetchAnswerListAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    id
 * @return {Promise}
 */
export const fetchAnswerListAPI = ({ questionnaireId, questionId, id }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires/${questionnaireId}/questions/${questionId}/answers?companyId=${id}&isActive=true`,
    });
};

/**
 * API call to create answer for a question
 *
 * @method createAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {Object}    data
 * @return {Promise}
 */
export const createAnswerAPI = ({ questionnaireId, questionId, data }) => {
    return axios({
        method: 'post',
        url: `/v2/questionnaires/${questionnaireId}/questions/${questionId}/answers`,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to update answer for a question
 *
 * @method updateAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    id
 * @param   {Object}    data
 * @return {Promise}
 */
export const updateAnswerAPI = ({ questionnaireId, questionId, data, id }) => {
    return axios({
        method: 'put',
        url: `/v2/questionnaires/${questionnaireId}/questions/${questionId}/answers/${id}`,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to delete answer for a question
 *
 * @method deleteAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    answerId
 * @return {Promise}
 */
export const deleteAnswerAPI = ({ questionnaireId, questionId, answerId }) => {
    return axios({
        method: 'delete',
        url: `/v2/questionnaires/${questionnaireId}/questions/${questionId}/answers/${answerId}`,
    });
};

/**
 * API call to fetch all answers
 *
 * @method fetchAllAnswersAPI
 * @param   {number}    questionnaireId
 * @param   {number}    companyId
 * @return {Promise}
 */
export const fetchAllAnswersAPI = ({ questionnaireId, companyId }) => {
    return axios({
        method: 'get',
        url: `v2/questionnaires/${questionnaireId}/answers?companyId=${companyId}&isActive=true`,
    });
};
