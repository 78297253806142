import { takeLatest } from 'redux-saga/effects';

import * as ticketsActions from '../actions';
import {
    fetchMessagesSaga,
    fetchMessageTypesSaga,
    createMessageSaga,
    fetchMyCaseStatusesSaga,
    fetchMyCaseTypesSaga,
    fetchMyTicketsSaga,
    fetchVpaSaga,
    fetchMyTicketsDetailsSaga,
    updateMyCaseDetailsSaga,
    fetchMyTicketsTopicsSaga,
    fetchCustomerCaseCountsSaga,
    createTicketSaga,
    fetchCommentsSaga,
    createCommentSaga,
    updateCommentSaga,
    deleteCommentSaga,
    fetchUnidentifiedCaseSaga,
    fetchUnidentifiedCasesSaga,
    respondToUnidentifiedCaseSaga,
    editUnidentifiedCaseSaga,
    cancelUnidentifiedCaseSaga,
    cancelUnidentifiedCasesSaga,
    fetchUnidentifiedCaseTypesSaga,
    fetchAttachmentUrlSaga,
    fetchCaseSurveySaga,
    createCaseSurveySaga,
    fetchQuickSightUrlSaga,
    fetchCaseAssignmentsSaga,
    transferCaseSaga,
    transferCasesSaga,
    fetchPreppedAnswersSaga,
    fetchMultiplePreppedAnswersSaga,
    unFavoritePreppedAnswerSaga,
    favoritePreppedAnswerSaga,
    deletePreppedAnswerSaga,
    fetchAllSuggestedPAUpdatesSaga,
    fetchSuggestedPAUpdatesSaga,
    suggestPAUpdateSaga,
    approveSuggestedPAUpdateSaga,
    declineSuggestedPAUpdateSaga,
    fetchPreppedAnswerDetailsSaga,
    fetchAllCaseCountsSaga,
    nominatePreppedAnswerSaga,
    updatePreppedAnswerSaga,
    downgradeVpaSaga,
    completeVpaSaga,
    declineVpaSaga,
    fetchTicketsSaga,
    removeMyCaseAttachmentSaga,
    createCaseConversationMessageSaga,
    fetchCaseConversationSaga,
    createMyCaseConversationMessageSaga,
    fetchMyCaseConversationSaga,
    fetchCaseDetailsSaga,
    updateCaseDetailsSaga,
    removeCaseAttachmentSaga,
    fetchTicketCountsSaga,
    fetchContentLinksSaga,
    fetchCaseTopicsSaga,
    createPreppedAnswerSaga,
    transferPreppedAnswerSaga,
    linkPreppedAnswerSaga,
    createResourcesSaga,
    updateResourcesSaga,
    deleteResourcesSaga,
    unlinkPreppedAnswerSaga,
    fetchCaseSourcesSaga,
    fetchCaseTasksSaga,
    createCaseTaskSaga,
    updateCaseTaskSaga,
    assignCaseTaskSaga,
    acceptCaseTaskSaga,
    activateCaseTaskSaga,
    completeCaseTaskSaga,
    deleteCaseTaskSaga,
    fetchCaseTaskTypesSaga,
    fetchCaseResourceTypesSaga,
    createNewCaseSaga,
    fetchCaseHistorySaga,
    reopenCaseSaga,
    cancelCaseSaga,
    createChatMessageReviewSaga,
    fetchChatMessageReviewsSaga,
    updateChatMessageReviewSaga,
} from './myTickets';

/**
 * Root saga for tickets
 * Triggers sagas related to all tools events
 *
 * @method ticketsSaga
 * @type   {Generator}
 */
function* ticketsSaga() {
    yield takeLatest(ticketsActions.fetchMessages.TRIGGER, fetchMessagesSaga);
    yield takeLatest(ticketsActions.getMessageTypes.TRIGGER, fetchMessageTypesSaga);
    yield takeLatest(ticketsActions.createMessage.TRIGGER, createMessageSaga);
    yield takeLatest(ticketsActions.fetchMyCaseStatuses.TRIGGER, fetchMyCaseStatusesSaga);
    yield takeLatest(ticketsActions.fetchMyCaseTypes.TRIGGER, fetchMyCaseTypesSaga);
    yield takeLatest(ticketsActions.fetchMyTickets.TRIGGER, fetchMyTicketsSaga);
    yield takeLatest(ticketsActions.fetchVpa.TRIGGER, fetchVpaSaga);
    yield takeLatest(ticketsActions.fetchMyTicketsDetails.TRIGGER, fetchMyTicketsDetailsSaga);
    yield takeLatest(ticketsActions.updateMyCaseDetails.TRIGGER, updateMyCaseDetailsSaga);
    yield takeLatest(ticketsActions.fetchMyTicketsTopics.TRIGGER, fetchMyTicketsTopicsSaga);
    yield takeLatest(ticketsActions.fetchCustomerCaseCounts.TRIGGER, fetchCustomerCaseCountsSaga);
    yield takeLatest(ticketsActions.fetchAllCaseCounts.TRIGGER, fetchAllCaseCountsSaga);
    yield takeLatest(ticketsActions.createTicket.TRIGGER, createTicketSaga);
    yield takeLatest(ticketsActions.fetchComments.TRIGGER, fetchCommentsSaga);
    yield takeLatest(ticketsActions.createComment.TRIGGER, createCommentSaga);
    yield takeLatest(ticketsActions.updateComment.TRIGGER, updateCommentSaga);
    yield takeLatest(ticketsActions.deleteComment.TRIGGER, deleteCommentSaga);
    yield takeLatest(ticketsActions.fetchUnidentifiedCase.TRIGGER, fetchUnidentifiedCaseSaga);
    yield takeLatest(ticketsActions.fetchUnidentifiedCases.TRIGGER, fetchUnidentifiedCasesSaga);
    yield takeLatest(
        ticketsActions.respondToUnidentifiedCase.TRIGGER,
        respondToUnidentifiedCaseSaga
    );
    yield takeLatest(ticketsActions.editUnidentifiedCase.TRIGGER, editUnidentifiedCaseSaga);
    yield takeLatest(ticketsActions.cancelUnidentifiedCase.TRIGGER, cancelUnidentifiedCaseSaga);
    yield takeLatest(ticketsActions.cancelUnidentifiedCases.TRIGGER, cancelUnidentifiedCasesSaga);
    yield takeLatest(
        ticketsActions.fetchUnidentifiedCaseTypes.TRIGGER,
        fetchUnidentifiedCaseTypesSaga
    );
    yield takeLatest(ticketsActions.fetchAttachmentUrl.TRIGGER, fetchAttachmentUrlSaga);
    yield takeLatest(ticketsActions.fetchCaseSurvey.TRIGGER, fetchCaseSurveySaga);
    yield takeLatest(ticketsActions.createCaseSurvey.TRIGGER, createCaseSurveySaga);
    yield takeLatest(ticketsActions.fetchQuickSightUrl.TRIGGER, fetchQuickSightUrlSaga);
    yield takeLatest(ticketsActions.fetchCaseAssignments.TRIGGER, fetchCaseAssignmentsSaga);
    yield takeLatest(ticketsActions.transferCase.TRIGGER, transferCaseSaga);
    yield takeLatest(ticketsActions.transferCases.TRIGGER, transferCasesSaga);
    yield takeLatest(ticketsActions.fetchPreppedAnswers.TRIGGER, fetchPreppedAnswersSaga);
    yield takeLatest(
        ticketsActions.fetchMultiplePreppedAnswers.TRIGGER,
        fetchMultiplePreppedAnswersSaga
    );
    yield takeLatest(ticketsActions.favoritePreppedAnswer.TRIGGER, favoritePreppedAnswerSaga);
    yield takeLatest(ticketsActions.unFavoritePreppedAnswer.TRIGGER, unFavoritePreppedAnswerSaga);
    yield takeLatest(ticketsActions.deletePreppedAnswer.TRIGGER, deletePreppedAnswerSaga);
    yield takeLatest(
        ticketsActions.fetchAllSuggestedPAUpdates.TRIGGER,
        fetchAllSuggestedPAUpdatesSaga
    );
    yield takeLatest(ticketsActions.fetchSuggestedPAUpdates.TRIGGER, fetchSuggestedPAUpdatesSaga);
    yield takeLatest(ticketsActions.suggestPAUpdate.TRIGGER, suggestPAUpdateSaga);
    yield takeLatest(ticketsActions.approveSuggestedPAUpdate.TRIGGER, approveSuggestedPAUpdateSaga);
    yield takeLatest(ticketsActions.declineSuggestedPAUpdate.TRIGGER, declineSuggestedPAUpdateSaga);

    yield takeLatest(
        ticketsActions.fetchPreppedAnswerDetails.TRIGGER,
        fetchPreppedAnswerDetailsSaga
    );
    yield takeLatest(ticketsActions.nominatePreppedAnswer.TRIGGER, nominatePreppedAnswerSaga);
    yield takeLatest(ticketsActions.downgradeVpa.TRIGGER, downgradeVpaSaga);
    yield takeLatest(ticketsActions.completeVpa.TRIGGER, completeVpaSaga);
    yield takeLatest(ticketsActions.declineVpa.TRIGGER, declineVpaSaga);
    yield takeLatest(ticketsActions.fetchTickets.TRIGGER, fetchTicketsSaga);
    yield takeLatest(ticketsActions.removeMyCaseAttachment.TRIGGER, removeMyCaseAttachmentSaga);
    yield takeLatest(
        ticketsActions.createCaseConversationMessage.TRIGGER,
        createCaseConversationMessageSaga
    );
    yield takeLatest(ticketsActions.fetchCaseConversation.TRIGGER, fetchCaseConversationSaga);
    yield takeLatest(
        ticketsActions.createMyCaseConversationMessage.TRIGGER,
        createMyCaseConversationMessageSaga
    );
    yield takeLatest(ticketsActions.fetchMyCaseConversation.TRIGGER, fetchMyCaseConversationSaga);
    yield takeLatest(ticketsActions.fetchCaseDetails.TRIGGER, fetchCaseDetailsSaga);
    yield takeLatest(ticketsActions.updateCaseDetails.TRIGGER, updateCaseDetailsSaga);
    yield takeLatest(ticketsActions.removeCaseAttachment.TRIGGER, removeCaseAttachmentSaga);
    yield takeLatest(ticketsActions.updatePreppedAnswer.TRIGGER, updatePreppedAnswerSaga);
    yield takeLatest(ticketsActions.fetchTicketCounts.TRIGGER, fetchTicketCountsSaga);
    yield takeLatest(ticketsActions.fetchContentLinks.TRIGGER, fetchContentLinksSaga);
    yield takeLatest(ticketsActions.createPreppedAnswer.TRIGGER, createPreppedAnswerSaga);
    yield takeLatest(ticketsActions.transferPreppedAnswer.TRIGGER, transferPreppedAnswerSaga);
    yield takeLatest(ticketsActions.linkPreppedAnswer.TRIGGER, linkPreppedAnswerSaga);
    yield takeLatest(ticketsActions.fetchCaseTopics.TRIGGER, fetchCaseTopicsSaga);
    yield takeLatest(ticketsActions.createResources.TRIGGER, createResourcesSaga);
    yield takeLatest(ticketsActions.updateResources.TRIGGER, updateResourcesSaga);
    yield takeLatest(ticketsActions.deleteResources.TRIGGER, deleteResourcesSaga);
    yield takeLatest(ticketsActions.unlinkPreppedAnswer.TRIGGER, unlinkPreppedAnswerSaga);
    yield takeLatest(ticketsActions.fetchCaseSources.TRIGGER, fetchCaseSourcesSaga);
    yield takeLatest(ticketsActions.fetchCaseTasks.TRIGGER, fetchCaseTasksSaga);
    yield takeLatest(ticketsActions.createCaseTask.TRIGGER, createCaseTaskSaga);
    yield takeLatest(ticketsActions.updateCaseTask.TRIGGER, updateCaseTaskSaga);
    yield takeLatest(ticketsActions.assignCaseTask.TRIGGER, assignCaseTaskSaga);
    yield takeLatest(ticketsActions.acceptCaseTask.TRIGGER, acceptCaseTaskSaga);
    yield takeLatest(ticketsActions.activateCaseTask.TRIGGER, activateCaseTaskSaga);
    yield takeLatest(ticketsActions.completeCaseTask.TRIGGER, completeCaseTaskSaga);
    yield takeLatest(ticketsActions.deleteCaseTask.TRIGGER, deleteCaseTaskSaga);
    yield takeLatest(ticketsActions.fetchCaseTaskTypes.TRIGGER, fetchCaseTaskTypesSaga);
    yield takeLatest(ticketsActions.fetchCaseResourceTypes.TRIGGER, fetchCaseResourceTypesSaga);
    yield takeLatest(ticketsActions.createNewCase.TRIGGER, createNewCaseSaga);
    yield takeLatest(ticketsActions.fetchCaseHistory.TRIGGER, fetchCaseHistorySaga);
    yield takeLatest(ticketsActions.reopenCase.TRIGGER, reopenCaseSaga);
    yield takeLatest(ticketsActions.cancelCase.TRIGGER, cancelCaseSaga);
    yield takeLatest(ticketsActions.createChatMessageReview.TRIGGER, createChatMessageReviewSaga);
    yield takeLatest(ticketsActions.fetchChatMessageReviews.TRIGGER, fetchChatMessageReviewsSaga);
    yield takeLatest(ticketsActions.updateChatMessageReview.TRIGGER, updateChatMessageReviewSaga);
}

export default ticketsSaga;

export {
    fetchMessagesSaga,
    fetchMessageTypesSaga,
    fetchCaseTopicsSaga,
    createMessageSaga,
    fetchMyCaseStatusesSaga,
    fetchMyCaseTypesSaga,
    fetchMyTicketsSaga,
    updateMyCaseDetailsSaga,
    fetchVpaSaga,
    fetchMyTicketsDetailsSaga,
    fetchMyTicketsTopicsSaga,
    fetchCustomerCaseCountsSaga,
    createTicketSaga,
    fetchCommentsSaga,
    createCommentSaga,
    updateCommentSaga,
    deleteCommentSaga,
    fetchUnidentifiedCaseSaga,
    fetchUnidentifiedCasesSaga,
    respondToUnidentifiedCaseSaga,
    editUnidentifiedCaseSaga,
    cancelUnidentifiedCaseSaga,
    cancelUnidentifiedCasesSaga,
    fetchUnidentifiedCaseTypesSaga,
    fetchAttachmentUrlSaga,
    fetchCaseSurveySaga,
    createCaseSurveySaga,
    fetchQuickSightUrlSaga,
    fetchCaseAssignmentsSaga,
    transferCaseSaga,
    transferCasesSaga,
    fetchPreppedAnswersSaga,
    fetchMultiplePreppedAnswersSaga,
    favoritePreppedAnswerSaga,
    unFavoritePreppedAnswerSaga,
    deletePreppedAnswerSaga,
    fetchAllSuggestedPAUpdatesSaga,
    fetchSuggestedPAUpdatesSaga,
    suggestPAUpdateSaga,
    approveSuggestedPAUpdateSaga,
    declineSuggestedPAUpdateSaga,
    fetchPreppedAnswerDetailsSaga,
    fetchAllCaseCountsSaga,
    nominatePreppedAnswerSaga,
    downgradeVpaSaga,
    completeVpaSaga,
    declineVpaSaga,
    fetchTicketsSaga,
    removeMyCaseAttachmentSaga,
    fetchCaseConversationSaga,
    createCaseConversationMessageSaga,
    fetchMyCaseConversationSaga,
    createMyCaseConversationMessageSaga,
    fetchCaseDetailsSaga,
    updateCaseDetailsSaga,
    removeCaseAttachmentSaga,
    updatePreppedAnswerSaga,
    fetchTicketCountsSaga,
    fetchContentLinksSaga,
    createPreppedAnswerSaga,
    transferPreppedAnswerSaga,
    linkPreppedAnswerSaga,
    createResourcesSaga,
    updateResourcesSaga,
    deleteResourcesSaga,
    unlinkPreppedAnswerSaga,
    fetchCaseSourcesSaga,
    fetchCaseTasksSaga,
    createCaseTaskSaga,
    updateCaseTaskSaga,
    assignCaseTaskSaga,
    acceptCaseTaskSaga,
    activateCaseTaskSaga,
    completeCaseTaskSaga,
    deleteCaseTaskSaga,
    fetchCaseTaskTypesSaga,
    fetchCaseResourceTypesSaga,
    createNewCaseSaga,
    fetchCaseHistorySaga,
    reopenCaseSaga,
    cancelCaseSaga,
    createChatMessageReviewSaga,
    fetchChatMessageReviewsSaga,
    updateChatMessageReviewSaga,
};
