/**
 * Initialize the 'ui.featureFlags' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    isHybridSsoEnabled: false,
    isMsaSignInOverrideEnabled: false,
    isImportHistoryEnabled: false,
    isAdministrationOptionEnabled: false,
    isAcaAdmin: false,
    hasAccessToHandbookMoveTool: false,
    isImpartnerEnabled: false,
};

export default INITIAL_STATE;
