import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { getThemeProps } from 'ravenjs/utils/theme';

import FormGroupTitle from './FormGroupTitle';
import ErrorList from './ErrorList';
import TitleField from './TitleField';
import Typography from '../../Typography';

const FormGroupStyled = styled.div`
    margin-bottom: ${({ isGroup }) => !isGroup && '15px'};
    /**
     * Add all of the remaining styles from theme
     */
    label:first-of-type + p {
        margin: ${({ marginLeft }) =>
            marginLeft ? `0 0 10px ${marginLeft} !important` : '0 0 10px 15px !important'};
    }
    ${getThemeProps('FormGroup.styles')};
`;

function FormGroup(props) {
    const {
        children,
        classNames,
        description,
        displayLabel,
        help,
        id,
        label,
        rawErrors,
        required,
        schema,
        uiSchema,
    } = props;

    const type = _get(schema, 'type');
    const hideRequired = _get(schema, 'hideRequired');
    const descriptionPosition = schema.descriptionPosition || 'bottom';
    const marginLeft = _get(children, '0.props.schema.descriptionMarginLeft');
    const value = _get(children, '0.props.formData');
    const color = _get(children, '0.props.schema.descriptionColor');
    const isFormGroup = schema.formGroup || false;
    const formGroupTitle = schema.formGroupTitle || null;
    const titleHelptext = _get(schema, 'helpText');
    const labelString = `${label}`;
    const uiOptions = _get(uiSchema, 'ui:options', {});
    const hideErrors = _get(schema, 'hideErrors', false);
    const pristine = _get(props, 'formContext.pristine', false);
    const taintedFields = _get(props, 'formContext.taintedFields', []);
    const removeOptionalLabel = _get(schema, 'removeOptionalLabel', false);
    const subDescription = _get(schema, 'subDescription', '');
    const subDescriptionColor = _get(schema, 'subDescriptionColor', '');
    const descriptionFontWeight = _get(schema, 'descriptionFontWeight');
    const showRequiredOnFormLoad = _get(schema, 'showRequiredOnFormLoad', false);
    const enableCustomTooltipLabel = _get(schema, 'enableCustomTooltipLabel', false);
    const displayRequiredLabel =
        (!pristine || taintedFields.includes(id) || showRequiredOnFormLoad) &&
        required &&
        isEmpty(value);

    let showRequiredMark = required;
    if (!isFormGroup) {
        if (typeof value === 'number' && isEmpty(value)) {
            showRequiredMark = false;
        } else {
            showRequiredMark = displayRequiredLabel;
        }
    }

    let showOptionalMark = false;

    if (isFormGroup === false && !required && !removeOptionalLabel) {
        showOptionalMark = true;
    }

    const renderLabel = () =>
        !isFormGroup &&
        displayLabel && (
            <FormGroupTitle
                helpText={titleHelptext}
                id={id}
                hideRequired={hideRequired}
                required={showRequiredMark}
                title={labelString}
                uiOptions={uiOptions}
                showOptionalMark={showOptionalMark}
                enableCustomTooltipLabel={enableCustomTooltipLabel}
            />
        );

    const renderFormGroupTitle = () =>
        isFormGroup &&
        formGroupTitle && <TitleField id={id} title={formGroupTitle} uiOptions={uiOptions} />;

    const renderSubDescription = () => {
        return (
            <Typography
                gutterBottom="10px"
                gutterTop="0"
                gutterLeft="18px"
                gutterRight="10px"
                fontSize="14px"
                fontFamily="Roboto"
                color={subDescriptionColor}
            >
                {subDescription}
            </Typography>
        );
    };

    const renderDescriptionTop = () =>
        id !== 'root' &&
        descriptionPosition === 'top' &&
        React.cloneElement(description, {
            color,
            ...(descriptionFontWeight && { descriptionFontWeight }),
        });
    const renderDescriptionBottom = () =>
        id !== 'root' &&
        descriptionPosition === 'bottom' &&
        React.cloneElement(description, {
            color,
            ...(descriptionFontWeight && { descriptionFontWeight }),
        });

    return (
        <FormGroupStyled
            isGroup={isFormGroup}
            className={classNames}
            type={type}
            marginLeft={marginLeft}
        >
            {renderLabel()}
            {renderFormGroupTitle()}
            {renderDescriptionTop()}
            {!isEmpty(subDescription) ? renderSubDescription() : null}
            {children}
            {renderDescriptionBottom()}
            {hideErrors ? '' : <ErrorList errors={rawErrors} {...uiOptions} />}
            {help}
        </FormGroupStyled>
    );
}

FormGroup.propTypes = {
    children: PropTypes.node,
    classNames: PropTypes.string,
    description: PropTypes.element,
    displayLabel: PropTypes.bool,
    help: PropTypes.element,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    rawErrors: PropTypes.array,
    required: PropTypes.bool,
    schema: PropTypes.object,
    uiSchema: PropTypes.object,
};

FormGroup.defaultProps = {
    children: null,
    classNames: '',
    description: null,
    displayLabel: true,
    help: null,
    id: null,
    label: null,
    rawErrors: null,
    required: false,
    schema: {},
    uiSchema: {},
};

export default FormGroup;
