export const AIDA_FLAG = 'showAida';
export const TITLE = 'Ask ARIES';
export const BADGE_TITLE = 'Beta';
export const DESCRIPTION =
    'Get help with your basic HR and compliance questions with AI-enabled answers based on the Mineral Platform data that you trust every day.';
export const LIMIT_TEXT = '0/256';
export const LABEL_BUTTON = 'Ask';
export const CHAT_HEADER = 'Chat Log with ARIES';
export const CHAT_GREETING = 'Hello, how can I help you today?';
export const CHAT_WELCOME = '';
export const CHAT_WELCOME_POLICY = 'By continuing you agree to our';
export const CHAT_WELCOME_POLICY_LINK = 'AI Privacy Policy';
export const CHAT_ASSISTANCE = 'ARIES';
export const CHAT_USER = 'You';
export const PLACEHOLDER_CHATFORM = 'Ask an HR compliance question...';
export const SUGGESTIONS_TITLE = 'Questions you can ask me.';
export const SUGGESTIONS_WIDGET_TEXT = 'What are our meal and rest break requirements?';
export const SUGGESTIONS_WIDGET_LINK = 'Ask Assistant';
export const STATIC_ANSWER =
    '<h1>HTML Example with Formatting Elements</h1><p>This is an example of HTML with formatting elements such as <strong>bold</strong>, <em>italic</em>, and <u>underlined</u> text.</p><p>Here is some additional text to demonstrate paragraphs in HTML. You can use paragraphs to organize and structure your content effectively.</p><h2>Lists</h2><h3>Unordered Lists (ul)</h3><ul><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul><h3>Ordered Lists (ol)</h3><ol><li>Item A</li><li>Item B</li><li>Item C</li></ol><p>Another paragraph to demonstrate text content in HTML.</p><h2>3x3 Table</h2><table border="1"><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr><tr><td>Data 1A</td><td>Data 1B</td><td>Data 1C</td></tr><tr><td>Data 2A</td><td>Data 2B</td><td>Data 2C</td></tr><tr><td>Data 3A</td><td>Data 3B</td><td>Data 3C</td></tr></table><p>One more paragraph to conclude this HTML example.</p><h2>Code Block</h2><p>Below is an example of a code block:</p><pre><code>function greet() { console.log("Hello, world!"); } greet();</code></pre>';
export const ADDITIONAL_RESOURCES_TITLE =
    'For additional information related to my response, please see:';
export const ADDITIONAL_RESOURCES = [
    'California DLSE Fast Foodworker Minimum Wage FAQs',
    'Cal. Labor Code § 1474 - Min Wage Law',
    'Cal. Labor Code § 1475 - Min Wage Law',
];
export const STATES_LABEL = 'Please Select';
export const STATES = [
    'Federal',
    'Alaska',
    'California',
    'Georgia',
    'Illinois',
    'New York',
    'North Carolina',
    'Oregon',
    'Pennsylvania',
    'West Virginia',
    'Wyoming',
];
export const COMPANY_WIDGET = {
    TITLE: 'Your Company Info',
    TITLE_MODAL: 'Your Company Info and ARIES',
    DESCRIPTION_DASHBOARD:
        'We use your total employee count and U.S. states where you have operations to provide the most accurate compliance recommendations and notifications. Does everything look up to date?',
    LABEL:
        'Regulations vary greatly by location and organization size. Please ensure your company information, displayed below, is up to date. ARIES will use this information to provide you with the best answer.',
    LABEL_LINK: 'Why is this important?',
    LABEL_BUTTON: 'Okay',
    LABEL_EMPLOYEES: 'Employees',
    LABEL_STATES: 'States',
    REVIEW_COMPANY_INFO: 'Review Company Locations',
    UPDATE_COMPANY_INFO: 'Update Your Company Info',
    REFRESH: 'Refresh',
};

export const THANKS_FEEDBACK = 'Thank you for your feedback!';
export const SUMMARY_STATE = '${state}: ${numEmployees} Employees ${numLocations} Locations';
export const MAX_CHARACTERS = 256;
export const FEEDBACK_QUESTION = 'What went wrong with this response?';
export const FEEDBACK_OPTIONS = [
    { id: 1, label: 'It was incorrect.', value: 'incorrect' },
    { id: 2, label: 'It did not answer my question.', value: 'inappropriate' },
    {
        id: 3,
        label: 'It was inappropriate, offensive, and/or biased.',
        value: 'not answer my question',
    },
    { id: 4, label: 'Something else.', value: 'other' },
];
export const FEEDBACK_MAX_CHARACTERS = 100;
export const SHORT_STATIC_ANSWER =
    '<h1>HTML Example with Formatting Elements</h1><p>This is an example of HTML with formatting elements such as <strong>bold</strong>, <em>italic</em>, and <u>underlined</u> text.</p><p>One more paragraph to conclude this HTML example.</p><h2>Code Block</h2><p>Below is an example of a code block:</p><pre><code>function greet() { console.log("Hello, world!"); } greet();</code></pre>';
export const STATUS_COMPLETE = 'COMPLETE';
export const STATUS_OPEN = 'OPEN';
export const STATUS_IN_PROCESS = 'IN_PROCESS';
export const STATUS_ERROR = 'ERROR';
export const RESOURCE_TYPE_CONTENTFUL = 'contentful';
export const STATUS_PARTIAL_UPDATE = 'PARTIAL_UPDATE';
export const FEDERAL_OPTION = { abbreviation: 'FE', name: 'Federal' };
export const ANY_RECORDS = "It doesn't look like there are any records with this Id.";
export const ERROR_LABELS = {
    HEADER: 'The conversation ended because of an error.',
    DESCRIPTION:
        "I'm sorry, I'm having trouble answering your question right now.\n Please try again later.",
};
export const CHAT_ASK = 'To ask a question, select a jurisdiction (Federal or State):';
export const TRY_ARIES = 'Try ARIES™, your AI Assistant';
export const SELECTED_STATE_LABEL = 'You selected ${state}.';
export const ENDED_CONVERSATION =
    'You ended a conversation about ${state}. A summary will be sent to your email.';
export const TYPE_SEND_EMAIL = 'ai-chat-session-history';
export const SUGGESTIONS_OPTIONS = [
    { id: 1, label: 'What are our meal and rest break requirements?' },
    { id: 2, label: 'Is paid time off for jury duty required?' },
    { id: 3, label: 'Do I have to pay student interns?' },
];
export const INFORMATION_BANNER = [
    {
        displayText:
            "ARIES is down for maintenance. We're working to ensure you have the best Mineral AI experience possible. In the meantime, check out our HR Compliance section to review laws and compliance information.",
        id: 'ai-digital-assistant-enabled',
        active: true,
        dismissed: false,
        dismissible: false,
    },
];
export const COUNT = '| Count = ${count}';
export const CHAT_CONSIDERATIONS = {
    ACCURACY_DISCLAIMER_MESSAGE:
        "While I strive for accuracy, there might be times when my responses aren't 100% correct or complete",
    RESPONSE_DELAY_MESSAGE: 'My responses may take a few seconds to generate, so please be patient',
};
export const PII =
    'Please exclude any sensitive, personal, confidential, and HIPAA protected data from your questions to ARIES';
export const PII_WARNING =
    '<strong>Warning:</strong> Including personal identification information is a violation of our <a href="${privacyPolicyUrl}">AI Privacy Policy</a>. Please remove sensitive information before submitting your question.';

export const CONSENT_MODAL = {
    TITLE: 'Consent to Use Ask ARIES™ AI Services',
    DESCRIPTION:
        'To use ARIES™, our AI-powered digital assistant, we need your consent for data processing. By opting in, you agree to our <a href="${privacyPolicyUrl}">AI Privacy Policy</a>. Opting out will prevent you from using AI services.',
    ACTIONS: {
        ACCEPT: 'I Agree to Use Ask ARIES™',
        DECLINE: 'I Do Not Agree to Use Ask ARIES™',
    },
};

export const ARIES_AREAS_EXPERTISE = {
    TITLE: 'ARIES Expertise',
    DESCRIPTION:
        "I can assist by providing general information on basic federal and state law inquiries about discrimination, equal employment, hiring, leaves, managing employees, termination, and wages. I can't make decisions for you. For legal, tax, or payroll advice, please consult a qualified professional.",
    LABEL_BUTTON: 'Okay',
};
export const INFORMATIONAL_TEXT = {
    ADVISOR_SKU:
        'Feel free to ask me for clarification on my response or you can <a href="/my-cases/create" target="_blank">submit a case</a> to a Mineral Expert at any time.',
    NON_ADVISOR_SKU: 'Feel free to ask me for clarification on my response.',
};
export const ERROR_FEEDBACK =
    'Sorry!  There was an error saving your feedback.  Our support team has been notified.';
