import AddIcon from './AddIcon';
import AddUserIcon from './AddUserIcon';
import AddFileIcon from './AddFileIcon';
import AlertCircleOutline from './AlertCircleOutline';
import ArrowDownIcon from './ArrowDownIcon';
import ArrowRightIcon from './ArrowRightIcon';
import ArrowsIcon from './ArrowsIcon';
import ArrowUpIcon from './ArrowUpIcon';
import ArrowUpDefaultIcon from './ArrowUpDefaultIcon';
import ArrowDownDefaultIcon from './ArrowDownDefaultIcon';
import BackIcon from './BackIcon';
import CheckIcon from './CheckIcon';
import CheckboxEmptyIcon from './CheckboxEmptyIcon';
import CheckboxFilledIcon from './CheckboxFilledIcon';
import CloseIcon from './CloseIcon';
import ClosePanelIcon from './ClosePanelIcon';
import ContentType2MinHrIcon from './ContentType2MinHrIcon';
import ContentTypeChartsIcon from './ContentTypeChartsIcon';
import ContentTypeChecklistsIcon from './ContentTypeChecklistsIcon';
import ContentTypeFormsIcon from './ContentTypeFormsIcon';
import ContentTypeGuidesIcon from './ContentTypeGuidesIcon';
import ContentTypeIcon from './ContentTypeIcon';
import ContentTypeLawAlertsIcon from './ContentTypeLawAlertsIcon';
import ContentTypeLawsIcon from './ContentTypeLawsIcon';
import ContentTypeLettersIcon from './ContentTypeLettersIcon';
import ContentTypePoliciesIcon from './ContentTypePoliciesIcon';
import ContentTypeQAIcon from './ContentTypeQAIcon';
import ContentTypeToolkitsIcon from './ContentTypeToolkitsIcon';
import ContentTypeWebinarsIcon from './ContentTypeWebinarsIcon';
import CopyLinkIcon from './CopyLinkIcon';
import DocumentIcon from './DocumentIcon';
import ExternalLinkIcon from './ExternalLinkIcon';
import EyeIcon from './EyeIcon';
import ImportUserIcon from './ImportUserIcon';
import GearIcon from './GearIcon';
import GearsIcon from './GearsIcon';
import GridIcon from './GridIcon';
import GripIcon from './GripIcon';
import HamburgerMenuIcon from './HamburgerMenuIcon';
import HandbookIcon from './HandbookIcon';
import HelpIcon from './HelpIcon';
import HelpCloseIcon from './HelpCloseIcon';
import HomeIcon from './HomeIcon';
import HRFitnessTestIcon from './HRFitnessTestIcon';
import InfoIcon from './InfoIcon';
import LaptopIcon from './LaptopIcon';
import LoadingIcon from './LoadingIcon';
import LogoutIcon from './LogoutIcon';
import MediaIcon from './MediaIcon';
import MoreIcon from './MoreIcon';
import NavigationArrowLeft from './NavigationArrowLeft';
import NavigationArrowRight from './NavigationArrowRight';
import OpenPanelIcon from './OpenPanelIcon';
import PaginationNextIcon from './PaginationNextIcon';
import PaginationPrevIcon from './PaginationPrevIcon';
import PointerIcon from './PointerIcon';
import ProfileIcon from './ProfileIcon';
import SearchActiveIcon from './SearchActiveIcon';
import SearchIcon from './SearchIcon';
import SendIcon from './SendIcon';
import ShareIcon from './ShareIcon';
import FavoriteIcon from './FavoriteIcon';
import ThumbsDownIcon from './ThumbsDownIcon';
import ThumbsUpIcon from './ThumbsUpIcon';
import FeedbackIconTooltip from './FeedbackIconTooltip';
import FilterIcon from './FilterIcon';
import ExpandedIcon from './ExpandedIcon';
import AlertIcon from './AlertIcon';
import AlertIconCounter from './AlertIconCounter';
import FpoIcon from './FpoIcon';
import AddCircleIcon from './AddCircleIcon';
import TrashIcon from './TrashIcon';
import ThrashIcon from './ThrashIcon';
import EditIcon from './EditIcon';
import RestoreIcon from './RestoreIcon';
import ViewDocumentIcon from './ViewDocumentIcon';
import DownloadIcon from './DownloadIcon';
import PrintIcon from './PrintIcon';
import PlaceholderIcon from './PlaceholderIcon';
import PlayIcon from './PlayIcon';
import DemoIcon from './DemoIcon';
import RiskIcon from './RiskIcon';
import NewIcon from './NewIcon';
import WarningIcon from './WarningIcon';
import LargeCalendarIcon from './LargeCalendarIcon';
import ReadIcon from './ReadIcon';
import PolicyAdvisor from './PolicyAdvisor';
import AngleLeftIcon from './AngleLeftIcon';
import AngleRightIcon from './AngleRightIcon';
import CalendarIcon from './CalendarIcon';
import CalendarCheckIcon from './CalendarCheckIcon';
import ConvertIcon from './ConvertIcon';
import ArrowSectionIcon from './ArrowSectionIcon';
import ZoomInIcon from './ZoomInIcon';
import ZoomOutIcon from './ZoomOutIcon';
import MobileCaretIcon from './MobileCaretIcon';
import GraphIcon from './GraphIcon';
import MultiPeopleIcon from './MultiPeopleIcon';
import EnrollIcon from './EnrollIcon';
import RectangleIcon from './RectangleIcon';
import TrophyIcon from './TrophyIcon';
import TrophyPlusIcon from './TrophyPlusIcon';
import TrophySmallIcon from './TrophySmallIcon';
import LibraryIcon from './LibraryIcon';
import LiveConsultationValueIcon from './LiveConsultationValueIcon';
import TrainingIcon from './TrainingIcon';
import TrainingValueIcon from './TrainingValueIcon';
import ComplianceValueIcon from './ComplianceValueIcon';
import HandbookValueIcon from './HandbookValueIcon';
import BenefitsDocumentCreatorValueIcon from './BenefitsDocumentCreatorValueIcon';
import SafetyIcon from './SafetyIcon';
import ActionListIcon from './ActionListIcon';
import ArrowUpCircleIcon from './ArrowUpCircleIcon';
import AttachmentFileIcon from './AttachmentFileIcon';
import IntelligenceIcon from './IntelligenceIcon';
import LocalPolicyIcon from './LocalPolicyIcon';
import SafetyCoursesIcon from './SafetyCoursesIcon';
import SafetyContentLibraryIcon from './SafetyContentLibraryIcon';
import SafetyManualTemplateIcon from './SafetyManualTemplateIcon';
import HandbookBuilderIcon from './HandbookBuilderIcon';
import SafetyAdvisorIcon from './SafetyAdvisorIcon';
import OshaLogIcon from './OshaLogIcon';
import ToolboxTalksIcon from './ToolboxTalksIcon';
import AlertIconTriangle from './AlertIconTriangle';
import CheckboxRoundIcon from './CheckboxRoundIcon';
import ArrowIconRight from './ArrowIconRight';
import ArrowLargeUpIcon from './ArrowLargeUpIcon';
import ArrowLargeDownIcon from './ArrowLargeDownIcon';
import ArrowLargeLeftIcon from './ArrowLargeLeftIcon';
import ArrowLargeRightIcon from './ArrowLargeRightIcon';
import InsightCheckIcon from './InsightCheckIcon';
import UserIcon from './UserIcon';
import InfoLargeIcon from './InfoLargeIcon';
import WordFileIcon from './WordFileIcon';
import PdfFileIcon from './PdfFileIcon';
import DataIcon from './DataIcon';
import ArrowRightIconLarge from './ArrowRightIconLarge';
import ArrowLeftIconLarge from './ArrowLeftIconLarge';
import MouseIcon from './MouseIcon';
import LayoutIcon from './LayoutIcon';
import BookOpenIcon from './BookOpenIcon';
import WatchIcon from './WatchIcon';
import MessageIcon from './MessageIcon';
import CrossIcon from './CrossIcon';
import AlertAndNotificationIcon from './AlertAndNotificationIcon';
import ComplyIcon from './ComplyIcon';
import LivingHandbookIcon from './LivingHandbookIcon';
import SignaturesIcon from './SignaturesIcon';
import BenefitsDocumentCreatorIcon from './BenefitsDocumentCreatorIcon';
import SuccessIcon from './SuccessIcon';
import LockIcon from './LockIcon';
import ClockIcon from './ClockIcon';
import ExpandIcon from './ExpandIcon';
import MoreVerticalIcon from './MoreVerticalIcon';
import FilterLinesIcon from './FilterLinesIcon';
import MapPinIcon from './MapPinIcon';
import ToolTipIcon from './ToolTipIcon';
import ErrorIcon from './ErrorIcon';
import MessageSquareIcon from './MessageSquareIcon';
import EmailSmallIcon from './EmailSmallIcon';
import SessionExpiryIcon from './SessionExpiryIcon';
import ClipboardIcon from './ClipboardIcon';
import VPAIcon from './PreppedAnswerIcons/VPAIcon';
import NominatedVPAIcon from './PreppedAnswerIcons/NominatedVPAIcon';
import PAClockIcon from './PreppedAnswerIcons/ClockIcon';
import PACloneIcon from './PreppedAnswerIcons/CloneIcon';
import PACommentIcon from './PreppedAnswerIcons/CommentIcon';
import PACompleteIcon from './PreppedAnswerIcons/CompleteIcon';
import PADeclineIcon from './PreppedAnswerIcons/DeclineIcon';
import PADeleteIcon from './PreppedAnswerIcons/DeleteIcon';
import PADowngradeIcon from './PreppedAnswerIcons/DowngradeIcon';
import PAEditIcon from './PreppedAnswerIcons/EditIcon';
import PANominateIcon from './PreppedAnswerIcons/NominateIcon';
import PAResourceIcon from './PreppedAnswerIcons/ResourceIcon';
import PACircleCheckIcon from './PreppedAnswerIcons/CircleCheckIcon';
import MyFavoriteIcon from './MyFavoriteIcon';
import ImportAlertIcon from './ImportAlertIcon';
import ImportSuccessIcon from './ImportSuccessIcon';
import SlashIcon from './SlashIcon';
import AddNewFileIcon from './AddNewFileIcon';
import CrossIconWithCircle from './CrossIconWithCircle';
import FileIcon from './FileIcon';
import BookMineralIcon from './BookMineralIcon';
import AriesIcon from './AriesIcon';
import NewCheckboxEmptyIcon from './NewCheckboxEmptyIcon';
import DiamondIcon from './DiamondIcon';
import ProfileCircleIcon from './ProfileCircleIcon';
import RefreshNewIcon from './RefreshNewIcon';
import RefreshIcon from './RefreshIcon';
import FullscreenIcon from './FullscreenIcon';
import ExitFullscreenIcon from './ExitFullscreenIcon';
import ImageIcon from './ImageIcon';
import PauseIcon from './PauseIcon';
import ActiveIcon from './ActiveIcon';
import AriesAvatarIcon from './AriesAvatarIcon';
import HarassmentIcon from './HarassmentIcon';
import AlarmFilledIcon from './AlarmFilledIcon';
import ThreeDotsMoreIcon from './ThreeDotsMoreIcon';
import CheckListIcon from './CheckListIcon';
import WarningFilledIcon from './WarningFilledIcon';
import TimelapseFilledIcon from './TimelapseFilledIcon';
import StorefrontIcon from './StorefrontIcon';

const IconTypes = {
    addIcon: AddIcon,
    addUserIcon: AddUserIcon,
    addFileIcon: AddFileIcon,
    AddNewFileIcon,
    arrowDownIcon: ArrowDownIcon,
    arrowUpIcon: ArrowUpIcon,
    arrowUpDefaultIcon: ArrowUpDefaultIcon,
    arrowDownDefaultIcon: ArrowDownDefaultIcon,
    arrowsIcon: ArrowsIcon,
    backIcon: BackIcon,
    checkIcon: CheckIcon,
    checkboxEmptyIcon: CheckboxEmptyIcon,
    checkboxFilledIcon: CheckboxFilledIcon,
    closeIcon: CloseIcon,
    closePanelIcon: ClosePanelIcon,
    contentType2MinHrIcon: ContentType2MinHrIcon,
    contentTypeChartsIcon: ContentTypeChartsIcon,
    contentTypeChecklistsIcon: ContentTypeChecklistsIcon,
    contentTypeFormsIcon: ContentTypeFormsIcon,
    contentTypeGuidesIcon: ContentTypeGuidesIcon,
    contentTypeIcon: ContentTypeIcon,
    contentTypeLawAlertsIcon: ContentTypeLawAlertsIcon,
    contentTypeLawsIcon: ContentTypeLawsIcon,
    contentTypeLettersIcon: ContentTypeLettersIcon,
    contentTypePoliciesIcon: ContentTypePoliciesIcon,
    contentTypeQAIcon: ContentTypeQAIcon,
    contentTypeToolkitsIcon: ContentTypeToolkitsIcon,
    contentTypeWebinarsIcon: ContentTypeWebinarsIcon,
    copyLinkIcon: CopyLinkIcon,
    documentIcon: DocumentIcon,
    externalLinkIcon: ExternalLinkIcon,
    eyeIcon: EyeIcon,
    importUserIcon: ImportUserIcon,
    gearIcon: GearIcon,
    filterIcon: FilterIcon,
    gearsIcon: GearsIcon,
    gridIcon: GridIcon,
    gripIcon: GripIcon,
    alertCircleOutline: AlertCircleOutline,
    searchActiveIcon: SearchActiveIcon,
    searchIcon: SearchIcon,
    sendIcon: SendIcon,
    shareIcon: ShareIcon,
    hamburgerMenuIcon: HamburgerMenuIcon,
    handbookIcon: HandbookIcon,
    homeIcon: HomeIcon,
    hrFitnessTestIcon: HRFitnessTestIcon,
    logoutIcon: LogoutIcon,
    mediaIcon: MediaIcon,
    moreIcon: MoreIcon,
    navigationArrowLeft: NavigationArrowLeft,
    navigationArrowRight: NavigationArrowRight,
    favoriteIcon: FavoriteIcon,
    thumbsDownIcon: ThumbsDownIcon,
    thumbsUpIcon: ThumbsUpIcon,
    arrowRightIcon: ArrowRightIcon,
    helpIcon: HelpIcon,
    helpCloseIcon: HelpCloseIcon,
    openPanelIcon: OpenPanelIcon,
    paginationNextIcon: PaginationNextIcon,
    paginationPrevIcon: PaginationPrevIcon,
    feedbackIconTooltip: FeedbackIconTooltip,
    pointerIcon: PointerIcon,
    profileIcon: ProfileIcon,
    expandedIcon: ExpandedIcon,
    fpoIcon: FpoIcon,
    alertIcon: AlertIcon,
    addCircleIcon: AddCircleIcon,
    trashIcon: TrashIcon,
    thrashIcon: ThrashIcon,
    editIcon: EditIcon,
    alertIconCounter: AlertIconCounter,
    restoreIcon: RestoreIcon,
    viewDocumentIcon: ViewDocumentIcon,
    printIcon: PrintIcon,
    downloadIcon: DownloadIcon,
    infoIcon: InfoIcon,
    laptopIcon: LaptopIcon,
    placeholderIcon: PlaceholderIcon,
    playIcon: PlayIcon,
    demoIcon: DemoIcon,
    riskIcon: RiskIcon,
    newIcon: NewIcon,
    warningIcon: WarningIcon,
    largeCalendarIcon: LargeCalendarIcon,
    readIcon: ReadIcon,
    policyAdvisor: PolicyAdvisor,
    angleLeftIcon: AngleLeftIcon,
    angleRightIcon: AngleRightIcon,
    calendarIcon: CalendarIcon,
    calendarCheckIcon: CalendarCheckIcon,
    convertIcon: ConvertIcon,
    arrowSectionIcon: ArrowSectionIcon,
    zoomInIcon: ZoomInIcon,
    zoomOutIcon: ZoomOutIcon,
    mobileCaretIcon: MobileCaretIcon,
    graphIcon: GraphIcon,
    multiPeopleIcon: MultiPeopleIcon,
    enrollIcon: EnrollIcon,
    rectangleIcon: RectangleIcon,
    trophyIcon: TrophyIcon,
    trophyPlusIcon: TrophyPlusIcon,
    trophySmallIcon: TrophySmallIcon,
    libraryIcon: LibraryIcon,
    liveConsultationValueIcon: LiveConsultationValueIcon,
    trainingIcon: TrainingIcon,
    trainingValueIcon: TrainingValueIcon,
    complianceValueIcon: ComplianceValueIcon,
    handbookValueIcon: HandbookValueIcon,
    benefitsDocumentCreatorValueIcon: BenefitsDocumentCreatorValueIcon,
    safetyIcon: SafetyIcon,
    actionListIcon: ActionListIcon,
    arrowUpCircleIcon: ArrowUpCircleIcon,
    attachmentFileIcon: AttachmentFileIcon,
    intelligenceIcon: IntelligenceIcon,
    localPolicyIcon: LocalPolicyIcon,
    safetyCoursesIcon: SafetyCoursesIcon,
    safetyContentLibraryIcon: SafetyContentLibraryIcon,
    safetyManualTemplateIcon: SafetyManualTemplateIcon,
    handbookBuilderIcon: HandbookBuilderIcon,
    safetyAdvisorIcon: SafetyAdvisorIcon,
    oshaLogIcon: OshaLogIcon,
    toolboxTalksIcon: ToolboxTalksIcon,
    alertIconTriangle: AlertIconTriangle,
    checkboxRoundIcon: CheckboxRoundIcon,
    arrowIconRight: ArrowIconRight,
    arrowLargeUpIcon: ArrowLargeUpIcon,
    arrowLargeDownIcon: ArrowLargeDownIcon,
    arrowLargeLeftIcon: ArrowLargeLeftIcon,
    arrowLargeRightIcon: ArrowLargeRightIcon,
    insightCheckIcon: InsightCheckIcon,
    userIcon: UserIcon,
    infoLargeIcon: InfoLargeIcon,
    ImportAlertIcon,
    ImportSuccessIcon,
    SlashIcon,
    PauseIcon,
    RefreshNewIcon,
    wordFileIcon: WordFileIcon,
    pdfFileIcon: PdfFileIcon,
    dataIcon: DataIcon,
    arrowLeftIconLarge: ArrowLeftIconLarge,
    arrowRightIconLarge: ArrowRightIconLarge,
    layoutIcon: LayoutIcon,
    bookOpenIcon: BookOpenIcon,
    watchIcon: WatchIcon,
    messageIcon: MessageIcon,
    crossIcon: CrossIcon,
    CrossIconWithCircle,
    alertAndNotificationIcon: AlertAndNotificationIcon,
    complyIcon: ComplyIcon,
    livingHandbookIcon: LivingHandbookIcon,
    signaturesIcon: SignaturesIcon,
    benefitsDocumentCreatorIcon: BenefitsDocumentCreatorIcon,
    successIcon: SuccessIcon,
    lockIcon: LockIcon,
    clockIcon: ClockIcon,
    expandIcon: ExpandIcon,
    moreVerticalIcon: MoreVerticalIcon,
    filterLinesIcon: FilterLinesIcon,
    mapPinIcon: MapPinIcon,
    toolTipIcon: ToolTipIcon,
    errorIcon: ErrorIcon,
    messageSquareIcon: MessageSquareIcon,
    emailSmallIcon: EmailSmallIcon,
    sessionExpiryIcon: SessionExpiryIcon,
    clipboardIcon: ClipboardIcon,
    vpaIcon: VPAIcon,
    nominatedVPAIcon: NominatedVPAIcon,
    paClockIcon: PAClockIcon,
    paCloneIcon: PACloneIcon,
    paCommentIcon: PACommentIcon,
    paCompleteIcon: PACompleteIcon,
    paDeclineIcon: PADeclineIcon,
    paDeleteIcon: PADeleteIcon,
    paDowngradeIcon: PADowngradeIcon,
    paEditIcon: PAEditIcon,
    paNominateIcon: PANominateIcon,
    paResourceIcon: PAResourceIcon,
    paCircleCheckIcon: PACircleCheckIcon,
    myFavoriteIcon: MyFavoriteIcon,
    fileIcon: FileIcon,
    bookMineralIcon: BookMineralIcon,
    ariesIcon: AriesIcon,
    newCheckboxEmptyIcon: NewCheckboxEmptyIcon,
    diamondIcon: DiamondIcon,
    profileCircleIcon: ProfileCircleIcon,
    refreshIcon: RefreshIcon,
    fullscreenIcon: FullscreenIcon,
    exitFullscreenIcon: ExitFullscreenIcon,
    imageIcon: ImageIcon,
    activeIcon: ActiveIcon,
    ariesAvatarIcon: AriesAvatarIcon,
    harassmentIcon: HarassmentIcon,
    alarmFilledIcon: AlarmFilledIcon,
    threeDotsMoreIcon: ThreeDotsMoreIcon,
    checkListIcon: CheckListIcon,
    warningFilledIcon: WarningFilledIcon,
    timelapseFilledIcon: TimelapseFilledIcon,
    storefrontIcon: StorefrontIcon,
};

export {
    AddIcon,
    AddUserIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    ArrowsIcon,
    AddFileIcon,
    AddNewFileIcon,
    BackIcon,
    CheckIcon,
    CheckboxEmptyIcon,
    CheckboxFilledIcon,
    CloseIcon,
    ClosePanelIcon,
    ContentType2MinHrIcon,
    ContentTypeChartsIcon,
    ContentTypeChecklistsIcon,
    ContentTypeFormsIcon,
    ContentTypeGuidesIcon,
    ContentTypeIcon,
    ContentTypeLawAlertsIcon,
    ContentTypeLawsIcon,
    ContentTypeLettersIcon,
    ContentTypePoliciesIcon,
    ContentTypeQAIcon,
    ContentTypeToolkitsIcon,
    ContentTypeWebinarsIcon,
    CopyLinkIcon,
    DocumentIcon,
    ExternalLinkIcon,
    EyeIcon,
    ImportUserIcon,
    ImportAlertIcon,
    ImportSuccessIcon,
    SlashIcon,
    PauseIcon,
    RefreshNewIcon,
    GearIcon,
    GearsIcon,
    GridIcon,
    GripIcon,
    AlertCircleOutline,
    SearchActiveIcon,
    SearchIcon,
    SendIcon,
    ShareIcon,
    HamburgerMenuIcon,
    HandbookIcon,
    HomeIcon,
    HRFitnessTestIcon,
    LoadingIcon,
    LogoutIcon,
    MediaIcon,
    MoreIcon,
    NavigationArrowLeft,
    NavigationArrowRight,
    FavoriteIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    ArrowRightIcon,
    HelpIcon,
    HelpCloseIcon,
    OpenPanelIcon,
    PaginationNextIcon,
    PaginationPrevIcon,
    FeedbackIconTooltip,
    FilterIcon,
    PointerIcon,
    ProfileIcon,
    ExpandedIcon,
    FpoIcon,
    AlertIcon,
    AddCircleIcon,
    TrashIcon,
    ThrashIcon,
    EditIcon,
    AlertIconCounter,
    RestoreIcon,
    ViewDocumentIcon,
    PrintIcon,
    DownloadIcon,
    InfoIcon,
    LaptopIcon,
    PlaceholderIcon,
    PlayIcon,
    DemoIcon,
    RiskIcon,
    NewIcon,
    WarningIcon,
    LargeCalendarIcon,
    ReadIcon,
    PolicyAdvisor,
    AngleLeftIcon,
    AngleRightIcon,
    CalendarIcon,
    CalendarCheckIcon,
    ConvertIcon,
    ArrowSectionIcon,
    ZoomInIcon,
    ZoomOutIcon,
    MobileCaretIcon,
    GraphIcon,
    MultiPeopleIcon,
    EnrollIcon,
    RectangleIcon,
    TrophyIcon,
    TrophyPlusIcon,
    TrophySmallIcon,
    IconTypes,
    LibraryIcon,
    LiveConsultationValueIcon,
    TrainingIcon,
    TrainingValueIcon,
    ComplianceValueIcon,
    HandbookValueIcon,
    BenefitsDocumentCreatorValueIcon,
    SafetyIcon,
    ActionListIcon,
    ArrowUpCircleIcon,
    AttachmentFileIcon,
    IntelligenceIcon,
    LocalPolicyIcon,
    SafetyCoursesIcon,
    SafetyContentLibraryIcon,
    SafetyManualTemplateIcon,
    HandbookBuilderIcon,
    SafetyAdvisorIcon,
    OshaLogIcon,
    ToolboxTalksIcon,
    AlertIconTriangle,
    CheckboxRoundIcon,
    ArrowIconRight,
    ArrowLargeUpIcon,
    ArrowLargeDownIcon,
    ArrowLargeLeftIcon,
    ArrowLargeRightIcon,
    InsightCheckIcon,
    ArrowUpDefaultIcon,
    ArrowDownDefaultIcon,
    UserIcon,
    InfoLargeIcon,
    WordFileIcon,
    PdfFileIcon,
    DataIcon,
    ArrowLeftIconLarge,
    ArrowRightIconLarge,
    MouseIcon,
    LayoutIcon,
    BookOpenIcon,
    WatchIcon,
    MessageIcon,
    CrossIcon,
    CrossIconWithCircle,
    SuccessIcon,
    LockIcon,
    ClockIcon,
    ExpandIcon,
    MoreVerticalIcon,
    FilterLinesIcon,
    MapPinIcon,
    ToolTipIcon,
    ErrorIcon,
    MessageSquareIcon,
    EmailSmallIcon,
    SessionExpiryIcon,
    ClipboardIcon,
    VPAIcon,
    NominatedVPAIcon,
    PAClockIcon,
    PACloneIcon,
    PACommentIcon,
    PACompleteIcon,
    PADeclineIcon,
    PADeleteIcon,
    PADowngradeIcon,
    PAEditIcon,
    PANominateIcon,
    PAResourceIcon,
    PACircleCheckIcon,
    MyFavoriteIcon,
    FileIcon,
    BookMineralIcon,
    AriesIcon,
    NewCheckboxEmptyIcon,
    DiamondIcon,
    ProfileCircleIcon,
    RefreshIcon,
    FullscreenIcon,
    ExitFullscreenIcon,
    ImageIcon,
    ActiveIcon,
    AriesAvatarIcon,
    HarassmentIcon,
    AlarmFilledIcon,
    ThreeDotsMoreIcon,
    CheckListIcon,
    WarningFilledIcon,
    TimelapseFilledIcon,
    StorefrontIcon,
};
