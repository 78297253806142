import { call, put, select } from 'redux-saga/effects';
import { get, isEmpty } from 'ravenjs/utils/lodash';

import {
    fetchMessagesAPI,
    createMessageAPI,
    fetchMessageTypesAPI,
    fetchMyCaseStatusesAPI,
    fetchMyTicketsAPI,
    fetchVpaAPI,
    fetchMyCasesTypesAPI,
    fetchMyTicketsTopicsAPI,
    fetchMyTicketsDetailsAPI,
    updateMyCaseDetailsAPI,
    fetchCustomerCaseCountsAPI,
    createTicketAPI,
    fetchCommentsAPI,
    createCommentAPI,
    updateCommentAPI,
    deleteCommentAPI,
    fetchUnidentifiedCaseAPI,
    fetchUnidentifiedCasesAPI,
    editUnidentifiedCaseAPI,
    respondToUnidentifiedCaseAPI,
    cancelUnidentifiedCaseAPI,
    cancelUnidentifiedCasesAPI,
    fetchUnidentifiedCaseTypesAPI,
    fetchAttachmentUrlAPI,
    fetchCaseSurveyAPI,
    createCaseSurveyAPI,
    fetchQuickSightUrlAPI,
    fetchCaseAssignmentsAPI,
    transferCaseAPI,
    transferCasesAPI,
    fetchPreppedAnswersAPI,
    fetchMultiplePreppedAnswersAPI,
    favoritePreppedAnswerAPI,
    unFavoritePreppedAnswerAPI,
    deletePreppedAnswerAPI,
    fetchPreppedAnswerDetailsAPI,
    fetchAllSuggestedPAUpdatesAPI,
    fetchSuggestedPAUpdatesAPI,
    suggestPAUpdateAPI,
    approveSuggestedPAUpdateAPI,
    declineSuggestedPAUpdateAPI,
    fetchAllCaseCountsAPI,
    nominatePreppedAnswerAPI,
    downgradeVpaAPI,
    completeVpaAPI,
    declineVpaAPI,
    fetchTicketsAPI,
    fetchCaseConversationAPI,
    createCaseConversationMessageAPI,
    fetchMyCaseConversationAPI,
    createMyCaseConversationMessageAPI,
    fetchCaseDetailsAPI,
    updateCaseDetailsAPI,
    removeMyCaseAttachmentAPI,
    removeCaseAttachmentAPI,
    updatePreppedAnswerAPI,
    fetchTicketCountsAPI,
    fetchCaseTopicsAPI,
    fetchContentLinksAPI,
    createPreppedAnswerAPI,
    transferPreppedAnswerAPI,
    linkPreppedAnswerAPI,
    createResourceAPI,
    updateResourceAPI,
    deleteResourceAPI,
    unlinkPreppedAnswerAPI,
    fetchCaseSourcesAPI,
    fetchCaseTasksAPI,
    createCaseTaskAPI,
    updateCaseTaskAPI,
    assignCaseTaskAPI,
    acceptCaseTaskAPI,
    activateCaseTaskAPI,
    completeCaseTaskAPI,
    deleteCaseTaskAPI,
    fetchCaseTaskTypesAPI,
    fetchCaseResourceTypesAPI,
    createNewCaseAPI,
    fetchCaseHistoryAPI,
    reopenCaseAPI,
    cancelCaseAPI,
    createChatMessageReviewAPI,
    fetchChatMessageReviewsAPI,
    updateChatMessageReviewAPI,
} from 'modules/apis';
import * as ticketsActions from '../actions';
import { getTickets } from '../reducer';

/**
 * Fetches My Tickets list
 *
 * @method fetchMyTicketsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyTicketsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMyTicketsAPI, payload);
        const myTickets = { ...get(response, 'included', {}), ...get(response, 'data', {}) };
        yield put(ticketsActions.fetchMyTickets.success(myTickets));
        return myTickets;
    } catch (error) {
        yield put(ticketsActions.fetchMyTickets.error(error));
        return error;
    }
}

/**
 * Fetches individual My Tickets' Details
 *
 * @method fetchMyTicketsDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyTicketsDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMyTicketsDetailsAPI, payload);
        const myTicketsDetails = { ...get(response, 'included', {}), ...get(response, 'data', {}) };
        yield put(ticketsActions.fetchMyTicketsDetails.success(myTicketsDetails));
        return myTicketsDetails;
    } catch (error) {
        yield put(ticketsActions.fetchMyTicketsDetails.error(error));
        return error;
    }
}

/**
 * Update case details for the MyCases
 *
 * @method updateMyCaseDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateMyCaseDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateMyCaseDetailsAPI, payload);
        yield put(ticketsActions.updateMyCaseDetails.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateMyCaseDetails.error(error));
        return error;
    }
}

/**
 * Fetches Ticket Types
 *
 * @method fetchMyCaseTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyCaseTypesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        const { caseTypes } = ticketsStore;

        if (isEmpty(caseTypes)) {
            const { payload } = action;
            const response = yield call(fetchMyCasesTypesAPI, payload);
            const responseCaseTypes = get(response, 'data', {});

            yield put(ticketsActions.fetchMyCaseTypes.success(responseCaseTypes));
            return responseCaseTypes;
        } else {
            yield put(ticketsActions.fetchMyCaseTypes.success(caseTypes));
            return caseTypes;
        }
    } catch (error) {
        yield put(ticketsActions.fetchMyCaseTypes.error(error));
        return error;
    }
}

/**
 * Fetches External Ticket Statuses
 *
 * @method fetchMyCaseStatusesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyCaseStatusesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        const { caseStatuses } = ticketsStore;

        if (isEmpty(caseStatuses)) {
            const { payload } = action;
            const response = yield call(fetchMyCaseStatusesAPI, payload);
            const responseCaseStatuses = get(response, 'data', {});

            yield put(ticketsActions.fetchMyCaseStatuses.success(responseCaseStatuses));
            return responseCaseStatuses;
        } else {
            yield put(ticketsActions.fetchMyCaseStatuses.success(caseStatuses));
            return caseStatuses;
        }
    } catch (error) {
        yield put(ticketsActions.fetchMyCaseStatuses.error(error));
        return error;
    }
}

/**
 * Function for create questionnaries
 *
 * @method fetchMyTicketsTopicsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyTicketsTopicsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMyTicketsTopicsAPI, payload);
        yield put(ticketsActions.fetchMyTicketsTopics.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchMyTicketsTopics.error(error));
        return error;
    }
}

/**
 * Function for fetching all case topics saga from network or redux store
 *
 * @method fetchCaseTopicsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseTopicsSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        let { caseTopics } = ticketsStore;

        if (isEmpty(caseTopics)) {
            const { payload } = action;
            const response = yield call(fetchCaseTopicsAPI, payload);
            caseTopics = get(response, 'data.data', {});

            yield put(ticketsActions.fetchCaseTopics.success(caseTopics));
        } else {
            yield put(ticketsActions.fetchCaseTopics.success(caseTopics));
        }

        return caseTopics;
    } catch (error) {
        yield put(ticketsActions.fetchCaseTopics.error(error));
        return error;
    }
}

/**
 * Function for fetching all case sources from network or redux store
 *
 * @method fetchCaseSourcesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseSourcesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        let { caseSources } = ticketsStore;

        if (isEmpty(caseSources)) {
            const { payload } = action;
            const response = yield call(fetchCaseSourcesAPI, payload);
            caseSources = get(response, 'data.data', {});

            yield put(ticketsActions.fetchCaseSources.success(caseSources));
        } else {
            yield put(ticketsActions.fetchCaseSources.success(caseSources));
        }

        return caseSources;
    } catch (error) {
        yield put(ticketsActions.fetchCaseSources.error(error));
        return error;
    }
}

/**
 * Saga for getting historical user ticket data
 *
 * @method fetchCustomerCaseCountsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCustomerCaseCountsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCustomerCaseCountsAPI, payload);
        yield put(ticketsActions.fetchCustomerCaseCounts.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCustomerCaseCounts.error(error));
        return error;
    }
}

/**
 * Creates Ticket in Ticketing System
 *
 * @method createTicketSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createTicketSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createTicketAPI, payload);
        yield put(ticketsActions.createTicket.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createTicket.error(error));
        return error;
    }
}

/**
 * Fetches Comments from Ticketing System
 *
 * @method fetchCommentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCommentsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCommentsAPI, payload);
        yield put(ticketsActions.fetchComments.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchComments.error(error));
        return error;
    }
}

/**
 * Creates Comment in Ticketing System
 *
 * @method createCommentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createCommentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createCommentAPI, payload);
        yield put(ticketsActions.createComment.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createComment.error(error));
        return error;
    }
}

/**
 * Updates Comment in Ticketing System
 *
 * @method updateCommentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateCommentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateCommentAPI, payload);
        yield put(ticketsActions.updateComment.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateComment.error(error));
        return error;
    }
}

/**
 * Deletes Comment in Ticketing System
 *
 * @method deleteCommentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* deleteCommentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deleteCommentAPI, payload);
        yield put(ticketsActions.deleteComment.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.deleteComment.error(error));
        return error;
    }
}

/**
 * Fetches an Unknown Tickets from Ticketing System
 *
 * @method fetchUnidentifiedCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUnidentifiedCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUnidentifiedCaseAPI, payload);
        yield put(ticketsActions.fetchUnidentifiedCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchUnidentifiedCase.error(error));
        return error;
    }
}

/**
 * Fetches Unknown Tickets from Ticketing System
 *
 * @method fetchUnidentifiedCasesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUnidentifiedCasesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUnidentifiedCasesAPI, payload);
        yield put(ticketsActions.fetchUnidentifiedCases.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchUnidentifiedCases.error(error));
        return error;
    }
}

/**
 * Edits an Unknown Ticket
 *
 * @method editUnidentifiedCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* editUnidentifiedCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(editUnidentifiedCaseAPI, payload);
        yield put(ticketsActions.editUnidentifiedCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.editUnidentifiedCase.error(error));
        return error;
    }
}

/**
 * POST to trigger email response to Unknown Ticket
 *
 * @method respondToUnidentifiedCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* respondToUnidentifiedCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(respondToUnidentifiedCaseAPI, payload);
        yield put(ticketsActions.respondToUnidentifiedCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.respondToUnidentifiedCase.error(error));
        return error;
    }
}

/**
 * Cancels an Unknown Ticket
 *
 * @method cancelUnidentifiedCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* cancelUnidentifiedCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(cancelUnidentifiedCaseAPI, payload);
        yield put(ticketsActions.cancelUnidentifiedCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.cancelUnidentifiedCase.error(error));
        return error;
    }
}

/**
 * Batch-cancels one or more Unknown Cases
 *
 * @method cancelUnidentifiedCasesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* cancelUnidentifiedCasesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(cancelUnidentifiedCasesAPI, payload);
        yield put(ticketsActions.cancelUnidentifiedCases.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.cancelUnidentifiedCases.error(error));
        return error;
    }
}

/**
 * Fetches Unknown Ticket Types from Ticketing System
 *
 * @method fetchUnidentifiedCaseTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUnidentifiedCaseTypesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUnidentifiedCaseTypesAPI, payload);
        yield put(ticketsActions.fetchUnidentifiedCaseTypes.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchUnidentifiedCaseTypes.error(error));
        return error;
    }
}

/**
 * Fetches attachment url from Ticketing System
 *
 * @method fetchAttachmentUrlSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchAttachmentUrlSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchAttachmentUrlAPI, payload);
        const location = get(response, 'data.meta.location', null);
        yield put(ticketsActions.fetchAttachmentUrl.success(location));
        return location;
    } catch (error) {
        yield put(ticketsActions.fetchAttachmentUrl.error(error));
        return error;
    }
}

/**
 * Fetch all Vpa
 *
 * @method fetchVpaSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchVpaSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchVpaAPI, payload);
        const myTickets = get(response, 'data', {});
        yield put(ticketsActions.fetchVpa.success(myTickets));
        return myTickets;
    } catch (error) {
        yield put(ticketsActions.fetchVpa.error(error));
        return error;
    }
}

/**
 * Fetch Ticketing Messages
 *
 * @method fetchMessagesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMessagesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMessagesAPI, payload);
        const messages = get(response, 'data', {});
        yield put(ticketsActions.fetchMessages.success(messages));
        return messages;
    } catch (error) {
        yield put(ticketsActions.fetchMessages.error(error));
        return error;
    }
}

/**
 * Create Message for a Case
 *
 * @method createMessageSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createMessageSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createMessageAPI, payload);
        const message = get(response, 'data', {});
        yield put(ticketsActions.createMessage.success(message));
        return message;
    } catch (error) {
        yield put(ticketsActions.createMessage.error(error));
        return error;
    }
}

/**
 * Fetch Ticketing Message Types
 *
 * @method fetchMessageTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMessageTypesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        let { messageTypes } = ticketsStore;

        if (isEmpty(messageTypes)) {
            const { payload } = action;
            const response = yield call(fetchMessageTypesAPI, payload);
            messageTypes = get(response, 'data.data', []);
            yield put(ticketsActions.getMessageTypes.success(messageTypes));
        } else {
            yield put(ticketsActions.getMessageTypes.success(messageTypes));
        }
        return messageTypes;
    } catch (error) {
        yield put(ticketsActions.getMessageTypes.error(error));
        return error;
    }
}

/**
 * Fetches Case Survey from Ticketing System
 *
 * @method fetchCaseSurveySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseSurveySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseSurveyAPI, payload);
        yield put(ticketsActions.fetchCaseSurvey.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseSurvey.error(error));
        return error;
    }
}

/**
 * Creates Case Survey in Ticketing System
 *
 * @method createCaseSurveySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createCaseSurveySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createCaseSurveyAPI, payload);
        yield put(ticketsActions.createCaseSurvey.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createCaseSurvey.error(error));
        return error;
    }
}

/**
 * Fetches AWS QuickSight URL
 *
 * @method fetchQuickSightUrlSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchQuickSightUrlSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchQuickSightUrlAPI, payload);
        yield put(ticketsActions.fetchQuickSightUrl.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchQuickSightUrl.error(error));
        return error;
    }
}

/**
 * Fetches Case Assignments
 *
 * @method fetchCaseAssignmentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseAssignmentsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseAssignmentsAPI, payload);
        yield put(ticketsActions.fetchCaseAssignments.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseAssignments.error(error));
        return error;
    }
}

/**
 * Transfers an individual case in Ticketing System
 *
 * @method transferCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* transferCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(transferCaseAPI, payload);
        yield put(ticketsActions.transferCase.success(get(response, 'Status', '')));
        return response;
    } catch (error) {
        yield put(ticketsActions.transferCase.error(error));
        return error;
    }
}

/**
 * Transfers Cases in Ticketing System
 *
 * @method transferCasesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* transferCasesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(transferCasesAPI, payload);
        yield put(ticketsActions.transferCases.success(get(response, 'Status', '')));
        return response;
    } catch (error) {
        yield put(ticketsActions.transferCases.error(error));
        return error;
    }
}

/**
 * Fetches Prepped Answers
 *
 * @method fetchPreppedAnswersSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchPreppedAnswersSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchPreppedAnswersAPI, payload);
        yield put(ticketsActions.fetchPreppedAnswers.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchPreppedAnswers.error(error));
        return error;
    }
}

/**
 * Fetches Multiple Prepped Answers
 *
 * @method fetchMultiplePreppedAnswersSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMultiplePreppedAnswersSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMultiplePreppedAnswersAPI, payload);
        yield put(ticketsActions.fetchMultiplePreppedAnswers.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchMultiplePreppedAnswers.error(error));
        return error;
    }
}

/**
 * Favorite Prepped Answers
 *
 * @method favoritePreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* favoritePreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(favoritePreppedAnswerAPI, payload);
        yield put(ticketsActions.favoritePreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.favoritePreppedAnswer.error(error));
        return error;
    }
}

/**
 * Favorite Prepped Answers
 *
 * @method unFavoritePreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* unFavoritePreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(unFavoritePreppedAnswerAPI, payload);
        yield put(ticketsActions.unFavoritePreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.unFavoritePreppedAnswer.error(error));
        return error;
    }
}

/**
 * Delete Prepped Answers
 *
 * @method deletePreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* deletePreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deletePreppedAnswerAPI, payload);
        yield put(ticketsActions.deletePreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.deletePreppedAnswer.error(error));
        return error;
    }
}

/**
 * Fetch Prepped Answers Details
 *
 * @method fetchPreppedAnswerDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchPreppedAnswerDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchPreppedAnswerDetailsAPI, payload);
        yield put(ticketsActions.fetchPreppedAnswerDetails.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchPreppedAnswerDetails.error(error));
        return error;
    }
}

/**
 * Fetches All Suggested Prepped Answer Edits
 *
 * @method fetchAllSuggestedPAUpdatesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchAllSuggestedPAUpdatesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchAllSuggestedPAUpdatesAPI, payload);
        yield put(ticketsActions.fetchAllSuggestedPAUpdates.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchAllSuggestedPAUpdates.error(error));
        return error;
    }
}

/**
 * Fetches Suggested Prepped Answer Edits
 *
 * @method fetchSuggestedPAUpdatesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchSuggestedPAUpdatesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchSuggestedPAUpdatesAPI, payload);
        yield put(ticketsActions.fetchSuggestedPAUpdates.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchSuggestedPAUpdates.error(error));
        return error;
    }
}

/**
 * Suggest Prepped Answer Edit
 *
 * @method suggestPAUpdateSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* suggestPAUpdateSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(suggestPAUpdateAPI, payload);
        yield put(ticketsActions.suggestPAUpdate.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.suggestPAUpdate.error(error));
        return error;
    }
}

/**
 * Approve Suggested Prepped Answer Edit
 *
 * @method approveSuggestedPAUpdateSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* approveSuggestedPAUpdateSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(approveSuggestedPAUpdateAPI, payload);
        yield put(ticketsActions.approveSuggestedPAUpdate.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.approveSuggestedPAUpdate.error(error));
        return error;
    }
}

/**
 * Decline Suggested Prepped Answer Edit
 *
 * @method declineSuggestedPAUpdateSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* declineSuggestedPAUpdateSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(declineSuggestedPAUpdateAPI, payload);
        yield put(ticketsActions.declineSuggestedPAUpdate.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.declineSuggestedPAUpdate.error(error));
        return error;
    }
}

/**
 * Fetch all case counts
 *
 * @method fetchAllCaseCountsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchAllCaseCountsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchAllCaseCountsAPI, payload);
        yield put(ticketsActions.fetchAllCaseCounts.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchAllCaseCounts.error(error));
        return error;
    }
}

/**
 * Nominate Prepped Answers
 *
 * @method nominatePreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* nominatePreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(nominatePreppedAnswerAPI, payload);
        yield put(ticketsActions.nominatePreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.nominatePreppedAnswer.error(error));
        return error;
    }
}

/**
 * Downgrade Vetted Prepped Answer
 *
 * @method downgradeVpaSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* downgradeVpaSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(downgradeVpaAPI, payload);
        yield put(ticketsActions.downgradeVpa.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.downgradeVpa.error(error));
        return error;
    }
}

/**
 * Complete Vetted Prepped Answer
 *
 * @method completeVpaSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* completeVpaSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(completeVpaAPI, payload);
        yield put(ticketsActions.completeVpa.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.completeVpa.error(error));
        return error;
    }
}

/**
 * Decline Vetted Prepped Answer
 *
 * @method declineVpaSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* declineVpaSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(declineVpaAPI, payload);
        yield put(ticketsActions.declineVpa.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.declineVpa.error(error));
        return error;
    }
}

/**
 * Fetch tickets for Case Cave
 *
 * @method fetchTicketsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchTicketsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchTicketsAPI, payload);
        yield put(ticketsActions.fetchTickets.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchTickets.error(error));
        return error;
    }
}

/**
 * Create a conversation message for a case
 *
 * @method createCaseConversationMessageSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createCaseConversationMessageSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createCaseConversationMessageAPI, payload);
        yield put(ticketsActions.createCaseConversationMessage.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createCaseConversationMessage.error(error));
        return error;
    }
}

/**
 * Fetch case conversation for a case
 *
 * @method fetchCaseConversationSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseConversationSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseConversationAPI, payload);
        yield put(ticketsActions.fetchCaseConversation.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseConversation.error(error));
        return error;
    }
}

/**
 * Create a conversation message for my case
 *
 * @method createMyCaseConversationMessageSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createMyCaseConversationMessageSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createMyCaseConversationMessageAPI, payload);
        yield put(
            ticketsActions.createMyCaseConversationMessage.success(get(response, 'data', {}))
        );
        return response;
    } catch (error) {
        yield put(ticketsActions.createMyCaseConversationMessage.error(error));
        return error;
    }
}

/**
 * Fetch case conversation for my case
 *
 * @method fetchMyCaseConversationSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchMyCaseConversationSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchMyCaseConversationAPI, payload);
        yield put(ticketsActions.fetchMyCaseConversation.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchMyCaseConversation.error(error));
        return error;
    }
}

/**
 * Fetch case details for the Case Cave
 *
 * @method fetchCaseDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseDetailsAPI, payload);
        yield put(ticketsActions.fetchCaseDetails.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseDetails.error(error));
        return error;
    }
}

/**
 * Update case details for the Case Cave
 *
 * @method updateCaseDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateCaseDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateCaseDetailsAPI, payload);
        yield put(ticketsActions.updateCaseDetails.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateCaseDetails.error(error));
        return error;
    }
}

/**
 * remove attachment from a my case
 *
 * @method removeMyCaseAttachmentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* removeMyCaseAttachmentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(removeMyCaseAttachmentAPI, payload);
        yield put(ticketsActions.removeMyCaseAttachment.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.removeMyCaseAttachment.error(error));
        return error;
    }
}

/**
 * remove attachment from a case
 *
 * @method removeCaseAttachmentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* removeCaseAttachmentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(removeCaseAttachmentAPI, payload);
        yield put(ticketsActions.removeCaseAttachment.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.removeCaseAttachment.error(error));
        return error;
    }
}

/**
 * Update Prepped Answer
 *
 * @method updatePreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updatePreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updatePreppedAnswerAPI, payload);
        yield put(ticketsActions.updatePreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updatePreppedAnswer.error(error));
        return error;
    }
}

/**
 * Fetch ticket count aggregates for Case Cave
 *
 * @method fetchTicketCountsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchTicketCountsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchTicketCountsAPI, payload);
        yield put(ticketsActions.fetchTicketCounts.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchTicketCounts.error(error));
        return error;
    }
}

/* Fetch Content Links
 *
 * @method fetchContentLinksSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchContentLinksSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchContentLinksAPI, payload);
        yield put(ticketsActions.fetchContentLinks.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchContentLinks.error(error));
        return error;
    }
}

/**
 * Create Prepped Answer for Case-Cave
 *
 * @method createPreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createPreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createPreppedAnswerAPI, payload);
        yield put(ticketsActions.createPreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createPreppedAnswer.error(error));
        return error;
    }
}

/**
 * Transfer Prepped Answer for Case-Cave
 *
 * @method transferPreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* transferPreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(transferPreppedAnswerAPI, payload);
        yield put(ticketsActions.transferPreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.transferPreppedAnswer.error(error));
        return error;
    }
}

/**
 * Link Prepped Answer to existing Case for Case-Cave
 *
 * @method linkPreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* linkPreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(linkPreppedAnswerAPI, payload);
        yield put(ticketsActions.linkPreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.linkPreppedAnswer.error(error));
        return error;
    }
}

/**
 *Create Resources For Prepped Answer
 *
 * @method  createResourcesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createResourcesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createResourceAPI, payload);
        yield put(ticketsActions.createResources.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createResources.error(error));
        return error;
    }
}

/**
 * Update Resources for prepped Answer
 *
 * @method updateResourcesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateResourcesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateResourceAPI, payload);
        yield put(ticketsActions.updateResources.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateResources.error(error));
        return error;
    }
}

/**
 * Delete  Resources for prepped Answer
 *
 * @method  deleteResourceSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* deleteResourcesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deleteResourceAPI, payload);
        yield put(ticketsActions.deleteResources.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.deleteResources.error(error));
        return error;
    }
}

/**
 * Unlink Prepped Answer from Resources
 *
 * @method  unlinkPreppedAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* unlinkPreppedAnswerSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(unlinkPreppedAnswerAPI, payload);
        yield put(ticketsActions.unlinkPreppedAnswer.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.unlinkPreppedAnswer.error(error));
        return error;
    }
}

/**
 * Fetch tasks for a given Case
 *
 * @method  fetchCaseTasksSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseTasksSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseTasksAPI, payload);
        yield put(ticketsActions.fetchCaseTasks.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseTasks.error(error));
        return error;
    }
}

/**
 * Creates a task for a given Case
 *
 * @method  fetchCaseTasksSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createCaseTaskAPI, payload);
        yield put(ticketsActions.createCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createCaseTask.error(error));
        return error;
    }
}

/**
 * Update a task for a given Case
 *
 * @method  updateCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateCaseTaskAPI, payload);
        yield put(ticketsActions.updateCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateCaseTask.error(error));
        return error;
    }
}

/**
 * Assign a task for a given Case
 *
 * @method  assignCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* assignCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(assignCaseTaskAPI, payload);
        yield put(ticketsActions.assignCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.assignCaseTask.error(error));
        return error;
    }
}

/**
 * Accept a task for a given Case
 *
 * @method  acceptCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* acceptCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(acceptCaseTaskAPI, payload);
        yield put(ticketsActions.acceptCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.acceptCaseTask.error(error));
        return error;
    }
}

/**
 * Activate a task for a given Case
 *
 * @method  activateCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* activateCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(activateCaseTaskAPI, payload);
        yield put(ticketsActions.activateCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.activateCaseTask.error(error));
        return error;
    }
}

/**
 * Complete a task for a given Case
 *
 * @method  completeCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* completeCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(completeCaseTaskAPI, payload);
        yield put(ticketsActions.completeCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.completeCaseTask.error(error));
        return error;
    }
}

/**
 * Delete a task for a given Case
 *
 * @method  deleteCaseTaskSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* deleteCaseTaskSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deleteCaseTaskAPI, payload);
        yield put(ticketsActions.deleteCaseTask.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.deleteCaseTask.error(error));
        return error;
    }
}

/**
 * Fetches case task types
 *
 * @method  fetchCaseTaskTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseTaskTypesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        const { taskTypes } = ticketsStore;

        if (isEmpty(taskTypes)) {
            const { payload } = action;
            const response = yield call(fetchCaseTaskTypesAPI, payload);
            const responseTaskTypes = get(response, 'data.data', []);

            yield put(ticketsActions.fetchCaseTaskTypes.success(responseTaskTypes));
            return responseTaskTypes;
        } else {
            yield put(ticketsActions.fetchCaseTaskTypes.success(taskTypes));
            return taskTypes;
        }
    } catch (error) {
        yield put(ticketsActions.fetchCaseTaskTypes.error(error));
        return error;
    }
}

/**
 * Fetches case resource types
 *
 * @method  fetchCaseResourceTypesSaga
 * @type    {Generator}'
 * @param   {Object} action The redux action
 * @return  {Object}
 */
export function* fetchCaseResourceTypesSaga(action) {
    try {
        const ticketsStore = yield select(getTickets);
        const { resourceTypes } = ticketsStore;

        if (isEmpty(resourceTypes)) {
            const { payload } = action;
            const response = yield call(fetchCaseResourceTypesAPI, payload);
            const responseResourceTypes = get(response, 'data.data', []);

            yield put(ticketsActions.fetchCaseResourceTypes.success(responseResourceTypes));
            return responseResourceTypes;
        } else {
            yield put(ticketsActions.fetchCaseResourceTypes.success(resourceTypes));
            return resourceTypes;
        }
    } catch (error) {
        yield put(ticketsActions.fetchCaseResourceTypes.error(error));
        return error;
    }
}

/**
 * Creates a new Case in Case Cave
 *
 * @method createNewCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createNewCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createNewCaseAPI, payload);
        yield put(ticketsActions.createNewCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createNewCase.error(error));
        return error;
    }
}

/**
 * Fetch history for a given Case
 *
 * @method  fetchCaseHistorySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCaseHistorySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCaseHistoryAPI, payload);
        yield put(ticketsActions.fetchCaseHistory.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchCaseHistory.error(error));
        return error;
    }
}

/**
 * Reopen a Case
 *
 * @method  reopenCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* reopenCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(reopenCaseAPI, payload);
        yield put(ticketsActions.reopenCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.reopenCase.error(error));
        return error;
    }
}

/**
 * Cancel a Case
 *
 * @method  cancelCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* cancelCaseSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(cancelCaseAPI, payload);
        yield put(ticketsActions.cancelCase.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.cancelCase.error(error));
        return error;
    }
}

/**
 * Create a Chat Message Review
 * @method createChatMessageReviewSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* createChatMessageReviewSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createChatMessageReviewAPI, payload);
        yield put(ticketsActions.createChatMessageReview.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.createChatMessageReview.error(error));
        return error;
    }
}

/**
 * Fetch Chat Message Reviews
 * @method fetchChatMessageReviewsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */

export function* fetchChatMessageReviewsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchChatMessageReviewsAPI, payload);
        yield put(ticketsActions.fetchChatMessageReviews.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.fetchChatMessageReviews.error(error));
        return error;
    }
}

/**
 * Update Chat Message Review
 * @method updateChatMessageReviewSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* updateChatMessageReviewSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateChatMessageReviewAPI, payload);
        yield put(ticketsActions.updateChatMessageReview.success(get(response, 'data', {})));
        return response;
    } catch (error) {
        yield put(ticketsActions.updateChatMessageReview.error(error));
        return error;
    }
}
