import { object } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import Tooltip from 'ravenjs/lib/Tooltip';
import { getThemeProps } from 'ravenjs/utils/theme';

import { CLICK_TO_BUY } from 'constants/clickToBuy';
import { StorefrontIcon } from 'components/Icons';
import { DEFAULT_COLORS } from 'constants/colors';

const SolutionCenterContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => theme.media.up('md')`
        margin: 0 0 0 0.825rem;
    `};
`;

function SolutionCenter({ theme }) {
    return (
        <Tooltip
            content={CLICK_TO_BUY.MARKET_PLACE.TITLE}
            padding="4px 16px"
            margin={0}
            arrowSize={5}
            placement="top"
            borderRadius="5px"
        >
            <SolutionCenterContainer>
                <StorefrontIcon
                    fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
                        theme,
                    })}
                    cursor="pointer"
                    isActive
                    onClick={() => {
                        window.location.href = '/solutions-center';
                    }}
                />
            </SolutionCenterContainer>
        </Tooltip>
    );
}

SolutionCenter.propTypes = {
    theme: object.isRequired,
};

export { SolutionCenter as SolutionCenterUnwrapped };
export default withTheme(SolutionCenter);
