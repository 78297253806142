import { array, arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { isEmpty } from 'ravenjs/utils/lodash';
import Link from 'ravenjs/lib/Link';

import { DEFAULT_COLORS } from 'constants/colors';
import { EVENTS } from 'constants/events';
import MESSAGES from 'constants/messages';
import { IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS } from 'constants/navigation';
import { SAFETY, SAFETY_MANUAL_TEMPLATE_ID } from 'constants/safety';
import Acl from 'modules/acl';
import { selectors as authSelectors } from 'modules/auth';
import { actions as contentfulActions } from 'modules/contentful';
import { actions as pendoActions } from 'modules/pendo';
import { actions as uiActions } from 'modules/ui';
import { selectors as userSelectors } from 'modules/user';
import { formatSubNavItems, getSubNavToProp } from 'utils/navigation';
import {
    downloadSafetyManualTemplate,
    filterSafetySubnavigation,
    isDisableSafetyItem,
    sendPendoEventToolClick,
} from 'utils/safety';
import { handleVergigChatClick } from 'utils/common';

import { MNL_INTELLIGENCE } from 'constants/intelligence';
import {
    HELP_TOPICS_STATE,
    HELP_MENU_TITLE,
    CONTACT_TECH_SUPPORT_STATE,
    CHAT_TECH_SUPPORT_STATE,
    CONTACT_HR_EXPERT_STATE,
    HELP_TOPICS_EVENT,
    CONTACT_TECH_SUPPORT_EVENT,
    CHAT_TECH_SUPPORT_EVENT,
    CONTACT_HR_EXPERT_EVENT,
} from 'constants/helpCenter';

const AppSubNavigationMainStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 1.1875rem;
`;

const LinkStyled = styled(Link)`
    color: ${DEFAULT_COLORS.BLACK} !important;
    :hover {
        color: ${DEFAULT_COLORS.LINK} !important;
        text-decoration: none;
    }
`;

const NavLinkStyled = styled(NavLink)`
    color: ${DEFAULT_COLORS.BLACK};
    & {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
    :hover {
        color: ${DEFAULT_COLORS.LINK};
        text-decoration: none;
    }
`;

const linkStyle = {
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginBlockStart: '.1875rem',
    marginBlockEnd: '.1875rem',
    marginInlineStart: '0',
    marginInlineEnd: '0',
};

class AppSubNavigationMain extends Component {
    static propTypes = {
        closeModal: func.isRequired,
        currentUser: object.isRequired,
        fetchSafetyManualTemplate: func.isRequired,
        isImpersonatingUser: bool,
        isLoading: func.isRequired,
        items: array,
        mainState: string,
        openModal: func.isRequired,
        safetyManualTemplateUrl: string,
        sendPendoEvent: func.isRequired,
        userCompany: object.isRequired,
        userPermissionsOriginal: arrayOf(
            shape({
                permissionKey: string,
            })
        ),
    };

    static defaultProps = {
        isImpersonatingUser: false,
        items: [],
        mainState: '',
        safetyManualTemplateUrl: '',
        userPermissionsOriginal: [],
    };

    refCallback = node => {
        const isEmployeeHandbooks = node && node.href.includes('handbooks-express');
        if (isEmployeeHandbooks) {
            node.onclick = () => {
                if (window.location.href.includes('/company-policies/handbooks-express')) {
                    window.location.reload();
                }
            };
        }
    };

    onClickNav = async (mainState, item) => {
        const { sendPendoEvent, currentUser, userCompany } = this.props;
        const pendoEvent = EVENTS.NAVIGATION.SELECT;

        pendoEvent.eventProperties.selection = `${mainState}:${item.state}`;
        sendPendoEvent(pendoEvent);

        if (mainState === MNL_INTELLIGENCE.MINTEL_NAV_STATE) {
            if (item.state === MNL_INTELLIGENCE.MINTEL_NAV_STATE) {
                const intelligencePendoEvent = EVENTS.INTELLIGENCE.REDIRECT_TO_DASHBOARD;
                intelligencePendoEvent.eventProperties.source = 'HR Compliance Dashboard';
                intelligencePendoEvent.eventProperties.userName = currentUser.userName;
                intelligencePendoEvent.eventProperties.companyId = userCompany.companyId;
                intelligencePendoEvent.eventProperties.companyName = userCompany.companyName;
                sendPendoEvent(intelligencePendoEvent);
            } else if (item.state === 'myinsights') {
                const intelligencePendoEvent = EVENTS.INTELLIGENCE.REDIRECT_TO_INSIGHTS;
                intelligencePendoEvent.eventProperties.source = 'HR Compliance Insights';
                intelligencePendoEvent.eventProperties.userName = currentUser.userName;
                intelligencePendoEvent.eventProperties.companyId = userCompany.companyId;
                intelligencePendoEvent.eventProperties.companyName = userCompany.companyName;
                sendPendoEvent(intelligencePendoEvent);
            }
        } else if (mainState.toLowerCase() === String(HELP_MENU_TITLE).toLowerCase()) {
            const helpCenterPendoEvent = EVENTS.NAVIGATION.HELP_CENTER;
            helpCenterPendoEvent.eventProperties.source = item.state;
            helpCenterPendoEvent.eventProperties.userName = currentUser.userName;
            helpCenterPendoEvent.eventProperties.companyId = userCompany.companyId;
            helpCenterPendoEvent.eventProperties.companyName = userCompany.companyName;
            switch (item.state) {
                case HELP_TOPICS_STATE:
                    helpCenterPendoEvent.event = HELP_TOPICS_EVENT;
                    break;
                case CONTACT_TECH_SUPPORT_STATE:
                    helpCenterPendoEvent.event = CONTACT_TECH_SUPPORT_EVENT;
                    break;
                case CHAT_TECH_SUPPORT_STATE:
                    helpCenterPendoEvent.event = CHAT_TECH_SUPPORT_EVENT;
                    break;
                case CONTACT_HR_EXPERT_STATE:
                    helpCenterPendoEvent.event = CONTACT_HR_EXPERT_EVENT;
                    break;
                default:
                    break;
            }
            sendPendoEvent(helpCenterPendoEvent);
        }
        if (mainState === SAFETY.toLowerCase()) {
            this.sendEventToolClick(item.label);
        }

        if (item.id === 'vergigchat') {
            await handleVergigChatClick();
        }
    };

    sendEventToolClick = toolName => {
        const { currentUser, sendPendoEvent, userCompany, userPermissionsOriginal } = this.props;

        sendPendoEventToolClick({
            currentUser,
            from: 'Navigation',
            sendPendoEvent,
            toolName,
            userCompany,
            userPermissionsOriginal,
        });
    };

    filterUnauthorized = item => {
        if (item.permissions && item.permissionToHide) {
            return Acl.check(item.permissions) && !Acl.check(item.permissionToHide);
        } else if (item.permissionToHide) {
            return !Acl.check(item.permissionToHide);
        } else if (item.permission) {
            return Acl.check(item.permission);
        }
        return filterSafetySubnavigation(item);
    };

    renderItems = (items, mainState) => {
        const {
            closeModal,
            currentUser,
            fetchSafetyManualTemplate,
            isImpersonatingUser,
            isLoading,
            openModal,
            safetyManualTemplateUrl,
            sendPendoEvent,
            userCompany,
            userPermissionsOriginal,
        } = this.props;
        const formattedItems = formatSubNavItems(items, mainState);
        const pendoData = {
            currentUser,
            sendPendoEvent,
            userCompany,
            userPermissionsOriginal,
        };

        return (
            <>
                {formattedItems.filter(this.filterUnauthorized).map(item => {
                    const navLinkDisabled =
                        (isImpersonatingUser &&
                            IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS.includes(item.to)) ||
                        isDisableSafetyItem(item.state);

                    if (mainState === 'training' && item.to.indexOf('http') !== -1) {
                        return (
                            <LinkStyled
                                style={linkStyle}
                                key={item.state}
                                href={item.to}
                                target="_blank"
                                onClick={() => this.onClickNav(mainState, item)}
                            >
                                {item.label}
                            </LinkStyled>
                        );
                    }

                    if (item.to === SAFETY_MANUAL_TEMPLATE_ID) {
                        const navLinkStyledPropsManual = navLinkDisabled
                            ? {
                                  disabled: true,
                                  onClick: e => {
                                      e.preventDefault();
                                  },
                                  title: MESSAGES.RESTRICTED_NAV_ITEM,
                              }
                            : {
                                  onClick: downloadSafetyManualTemplate({
                                      closeModal,
                                      fetchSafetyManualTemplate,
                                      isLoading,
                                      openModal,
                                      safetyManualTemplateUrl,
                                      sendEvent: this.sendEventToolClick,
                                      pendoData,
                                      product: { title: item.label },
                                  }),
                              };

                        const linkStyleManual = navLinkDisabled
                            ? { ...linkStyle, cursor: 'not-allowed' }
                            : linkStyle;
                        return (
                            <LinkStyled
                                style={linkStyleManual}
                                key={item.state}
                                href="#"
                                {...navLinkStyledPropsManual}
                            >
                                {item.label}
                            </LinkStyled>
                        );
                    }

                    const navLinkStyledProps = navLinkDisabled
                        ? {
                              disabled: true,
                              onClick: e => {
                                  e.preventDefault();
                              },
                              title: MESSAGES.RESTRICTED_NAV_ITEM,
                          }
                        : {
                              onClick: () => this.onClickNav(mainState, item),
                          };

                    return (
                        <NavLinkStyled
                            to={getSubNavToProp(mainState, item)}
                            innerRef={this.refCallback}
                            style={linkStyle}
                            key={item.state}
                            {...navLinkStyledProps}
                        >
                            {item.label}
                        </NavLinkStyled>
                    );
                })}
            </>
        );
    };

    render() {
        const { items, mainState } = this.props;
        return (
            !isEmpty(items) &&
            items.length > 0 && (
                <AppSubNavigationMainStyled>
                    {this.renderItems(items, mainState)}
                </AppSubNavigationMainStyled>
            )
        );
    }
}

const mapDispatchToProps = {
    closeModal: uiActions.closeModal,
    fetchSafetyManualTemplate: contentfulActions.fetchSafetyManualTemplate,
    isLoading: uiActions.isLoading,
    openModal: uiActions.openModal,
    sendPendoEvent: pendoActions.sendPendoEvent,
};

const mapStateToProps = createStructuredSelector({
    currentUser: userSelectors.getUserInfo,
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    safetyManualTemplateUrl: userSelectors.getSafetyManualTemplateUrl,
    userCompany: userSelectors.getUserCompany,
    userPermissionsOriginal: userSelectors.getUserPermissionsOriginal,
});

export { AppSubNavigationMain as AppSubNavigationMainUnwrapped };
export default connect(mapStateToProps, mapDispatchToProps)(AppSubNavigationMain);
