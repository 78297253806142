import React from 'react';
import _startCase from 'lodash/startCase';

import { get, isEmpty } from 'ravenjs/utils/lodash';

import {
    ContentType2MinHrIcon,
    ContentTypeChartsIcon,
    ContentTypeChecklistsIcon,
    ContentTypeFormsIcon,
    ContentTypeGuidesIcon,
    ContentTypeLawAlertsIcon,
    ContentTypeLawsIcon,
    ContentTypeLettersIcon,
    ContentTypePoliciesIcon,
    ContentTypeQAIcon,
    ContentTypeToolkitsIcon,
    ContentTypeWebinarsIcon,
} from 'components/Icons';

import {
    CONTENT_COLORS,
    CONTENT_NAMES,
    CONTENT_NAMES_SINGULAR,
    CONTENT_SECTION_NAMES,
    CONTENT_TYPE_ROUTES,
    CONTENT_TYPES,
    CONTENT_TYPES_MAPPED,
    DOCUMENT_TYPES_MAPPED,
    FILTER_DOCUMENT_TYPES,
    FILTER_DOCUMENT_TYPES_KEY,
    FILTER_DOCUMENT_TYPES_MAPPED,
    NAV_STATE_TYPES,
    SHOW_BANNER_EHS_FLAG,
    SECTIONS_WITH_FEEDBACK_MODAL,
    COMPLIANCE_SECTIONS_URL,
} from 'constants/content';
import DOCUMENTS from 'constants/documents';
import {
    HELP_CENTER_FLAG,
    USER_ASSISTANCE,
    USER_ASSISTANCE_PDF_LINK_AUTHORID,
    USER_ASSISTANCE_VIDEO_LINK_AUTHORID,
} from 'constants/helpCenter';
import { hasFeatureFlag } from 'utils/common';

import { DEFAULT_COLORS } from 'constants/colors';
import { MODALS } from 'constants/modals';
import { THUMBS_UP_OPTIONS, THUMBS_DOWN_OPTIONS } from 'constants/compliance';
import ThumbsFeedback from 'components/ToggleThumbs/ThumbsFeedback';

export const contentTypeToContentTypeMapped = (contentType, customMap) => {
    if (contentType === CONTENT_TYPES.LAW) {
        return CONTENT_TYPES_MAPPED.LAW;
    } else if (contentType === CONTENT_TYPES.LAW_ALERT) {
        return CONTENT_TYPES_MAPPED.LAW_ALERT;
    } else if (
        contentType === CONTENT_TYPES.QUESTION_AND_ANSWER ||
        contentType === CONTENT_TYPE_ROUTES.QUESTION_AND_ANSWER
    ) {
        return CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER;
    } else if (contentType === CONTENT_TYPES.TOOLKIT) {
        return CONTENT_TYPES_MAPPED.TOOLKIT;
    } else if (contentType === CONTENT_TYPES.CHARTS) {
        return CONTENT_TYPES_MAPPED.CHARTS;
    } else if (contentType === CONTENT_TYPES.HIRING) {
        return CONTENT_TYPES_MAPPED.HIRING;
    } else if (contentType === CONTENT_TYPES.HR_CHECKLISTS) {
        return CONTENT_TYPES_MAPPED.DOCUMENT;
    } else if (contentType === CONTENT_TYPES.GUIDES) {
        return CONTENT_TYPES_MAPPED.GUIDES;
    } else if (contentType === CONTENT_TYPES.TWO_MIN_HR) {
        return customMap ? CONTENT_TYPES_MAPPED.DOCUMENT : contentType;
    }

    return contentType;
};

export const contentTypeMappedToContentType = (contentType, returnEmpty) => {
    if (contentType === CONTENT_TYPES_MAPPED.LAW) {
        return CONTENT_TYPES.LAW;
    } else if (contentType === CONTENT_TYPES_MAPPED.LAW_ALERT) {
        return CONTENT_TYPES.LAW_ALERT;
    } else if (contentType === CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER) {
        return CONTENT_TYPES.QUESTION_AND_ANSWER;
    } else if (contentType === CONTENT_TYPES_MAPPED.TOOLKIT) {
        return CONTENT_TYPES.TOOLKIT;
    } else if (contentType === CONTENT_TYPES_MAPPED.TOOLKITS) {
        return CONTENT_TYPES.TOOLKIT;
    }
    return returnEmpty ? '' : contentType;
};

export const contentTypeNameToContentType = contentTypeName => {
    const contentType = contentTypeName.toLowerCase();

    if (contentType === 'guide/white paper') {
        return DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER;
    } else if (contentType === 'law alert') {
        return CONTENT_TYPES.LAW_ALERT;
    } else if (contentType === 'q&a') {
        return CONTENT_TYPES.QUESTION_AND_ANSWER;
    }
    return contentType;
};

export const getContentNameByContentType = contentType => {
    if (contentType === CONTENT_TYPES.TWO_MIN_HR) {
        return CONTENT_NAMES.TWO_MIN_HR;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.CHART) {
        return CONTENT_NAMES.CHART;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.CHECKLIST) {
        return CONTENT_NAMES.CHECKLIST;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.FORM) {
        return CONTENT_NAMES.FORM;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER) {
        return CONTENT_NAMES.GUIDE_WHITE_PAPER;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.INFOGRAPHIC) {
        return CONTENT_NAMES.INFOGRAPHIC;
    } else if (contentType === CONTENT_TYPES_MAPPED.LAW) {
        return CONTENT_NAMES.LAW;
    } else if (contentType === CONTENT_TYPES_MAPPED.LAW_ALERT) {
        return CONTENT_NAMES.LAW_ALERT;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.LETTER) {
        return CONTENT_NAMES.LETTER;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.POLICY) {
        return CONTENT_NAMES.POLICY;
    } else if (contentType === CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER) {
        return CONTENT_NAMES.QUESTION_AND_ANSWER;
    } else if (contentType === CONTENT_TYPES.TOOLKIT) {
        return CONTENT_NAMES.TOOLKIT;
    } else if (contentType === CONTENT_TYPES_MAPPED.WEBINARS) {
        return CONTENT_NAMES.WEBINARS;
    } else if (contentType === CONTENT_TYPES_MAPPED.HELP_CENTER) {
        return CONTENT_NAMES.HELP_CENTER;
    }
    return undefined;
};

export const getContentColorByContentTypeMapped = contentType => {
    if (contentType === DOCUMENT_TYPES_MAPPED.CHART) {
        return CONTENT_COLORS.CHART;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.CHECKLIST) {
        return CONTENT_COLORS.CHECKLIST;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.FORM) {
        return CONTENT_COLORS.FORM;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER) {
        return CONTENT_COLORS.GUIDE_WHITE_PAPER;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.INFOGRAPHIC) {
        return CONTENT_COLORS.INFOGRAPHIC;
    } else if (contentType === CONTENT_TYPES_MAPPED.LAW || contentType === CONTENT_TYPES.LAW) {
        return CONTENT_COLORS.LAW;
    } else if (
        contentType === CONTENT_TYPES_MAPPED.LAW_ALERT ||
        contentType === CONTENT_TYPES.LAW_ALERT
    ) {
        return CONTENT_COLORS.LAW_ALERT;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.LETTER) {
        return CONTENT_COLORS.LETTER;
    } else if (contentType === DOCUMENT_TYPES_MAPPED.POLICY) {
        return CONTENT_COLORS.POLICY;
    } else if (
        contentType === CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER ||
        contentType === CONTENT_TYPES.QUESTION_AND_ANSWER
    ) {
        return CONTENT_COLORS.QUESTION_AND_ANSWER;
    } else if (contentType === CONTENT_TYPES.TOOLKIT) {
        return CONTENT_COLORS.TOOLKIT;
    } else if (contentType === CONTENT_TYPES.TWO_MIN_HR) {
        return CONTENT_COLORS.TWO_MIN_HR;
    } else if (contentType === CONTENT_TYPES_MAPPED.WEBINARS) {
        return CONTENT_COLORS.WEBINARS;
    }
    return undefined;
};

export const getContentPath = (contentType, contentId, isFormsAndLetters = false) => {
    let contentPath = '';

    if (isFormsAndLetters) {
        switch (contentType) {
            case DOCUMENT_TYPES_MAPPED.CHART:
                contentPath = `/templates/charts/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.CHECKLIST:
                contentPath = `/templates/checklists/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.FORM:
                contentPath = `/templates/forms/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER:
                contentPath = `/templates/guides/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.LETTER:
                contentPath = `/templates/letters/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.POLICY:
                contentPath = `/templates/policies/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.TOOLKIT:
            case CONTENT_TYPES_MAPPED.TOOLKITS:
                contentPath = `/templates/toolkits/${contentId}/details`;
                break;
            default:
                contentPath = `/futurework`;
                break;
        }
    } else {
        switch (contentType) {
            case CONTENT_TYPES_MAPPED.LAW:
                contentPath = `/hr-compliance/laws/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.LAW_ALERT:
                contentPath = `/hr-compliance/law-alerts/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER:
                contentPath = `/resources/q-and-a/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.TWO_MIN_HR:
                contentPath = `/resources/videos/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.CHART:
                contentPath = `/resources/charts/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.CHECKLIST:
                contentPath = `/resources/hr-checklists/${contentId}/details`;
                break;
            case DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER:
                contentPath = `/resources/guides/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.WEBINARS:
                contentPath = `/resources/webinars/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.WEBINARS_ON_DEMAND:
                contentPath = `/resources/webinars/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.JOB_DESCRIPTION:
                contentPath = `/tools/jobs/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.FEATURED:
                contentPath = `/featured-content/${contentId}/details`;
                break;
            case CONTENT_TYPES_MAPPED.TOOLKITS:
                contentPath = `/templates/toolkits/${contentId}/details`;
                break;
            default:
                contentPath = `/futurework`;
                break;
        }
    }

    return contentPath;
};

export const getDefaultCategoryLabelByNavState = navState => {
    if (navState === NAV_STATE_TYPES.CHART) {
        return CONTENT_NAMES.CHART;
    } else if (navState === NAV_STATE_TYPES.CHECKLIST) {
        return CONTENT_NAMES.CHECKLIST;
    } else if (navState === NAV_STATE_TYPES.FORM) {
        return CONTENT_NAMES.FORM;
    } else if (navState === NAV_STATE_TYPES.GUIDE_WHITE_PAPER) {
        return CONTENT_NAMES.GUIDE_WHITE_PAPER;
    } else if (navState === NAV_STATE_TYPES.INFOGRAPHIC) {
        return CONTENT_NAMES.INFOGRAPHIC;
    } else if (navState === NAV_STATE_TYPES.LETTER) {
        return CONTENT_NAMES.LETTER;
    } else if (navState === NAV_STATE_TYPES.POLICY) {
        return CONTENT_NAMES.POLICY;
    } else if (navState === NAV_STATE_TYPES.TOOLKIT) {
        return CONTENT_NAMES.TOOLKIT;
    }
    return '';
};

export const getDocumentTypeMappedByFilterDocumentType = filterDocumentType => {
    const documentTypeMappedKey = filterDocumentType.toUpperCase();

    if (filterDocumentType === FILTER_DOCUMENT_TYPES.GUIDE_WHITE_PAPER) {
        return DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER;
    } else {
        return DOCUMENT_TYPES_MAPPED[documentTypeMappedKey];
    }
};

export const getFilterDocumentTypeByMappedType = mappedType => {
    if (!mappedType) {
        return '';
    }

    const filterDocumentTypeKey = mappedType.toUpperCase();

    if (mappedType === DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER) {
        return FILTER_DOCUMENT_TYPES.GUIDE_WHITE_PAPER;
    } else {
        return FILTER_DOCUMENT_TYPES_MAPPED[filterDocumentTypeKey];
    }
};

export const getLabelByDocumentType = (documentType, isSingular = false) => {
    if (!documentType) {
        return '';
    }

    const LABELS = isSingular ? CONTENT_NAMES_SINGULAR : CONTENT_NAMES;

    documentType = documentType.toUpperCase();

    if (documentType === '2MHR') {
        return LABELS.TWO_MIN_HR;
    } else if (documentType === 'GUIDE/WHITE PAPER' || documentType === 'GUIDE-WHITE-PAPER') {
        return LABELS.GUIDE_WHITE_PAPER;
    } else if (documentType === 'LAWS') {
        return LABELS.LAW;
    } else if (documentType === 'LAWALERT' || documentType === 'LAW-ALERTS') {
        return LABELS.LAW_ALERT;
    } else if (
        documentType === CONTENT_TYPES.QUESTION_AND_ANSWER.toUpperCase() ||
        documentType === CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER.toUpperCase() ||
        documentType === CONTENT_NAMES.QUESTION_AND_ANSWER ||
        documentType === DOCUMENTS.CONTENT_TYPES['Q&A'].toUpperCase()
    ) {
        return LABELS.QUESTION_AND_ANSWER;
    } else if (documentType === 'TOOLKITS') {
        return LABELS.TOOLKIT;
    } else if (documentType === 'HELP-CENTER') {
        return LABELS.HELP_CENTER;
    } else {
        return LABELS[documentType] || '';
    }
};

export const getSectionLabel = sectionName => {
    const sectionNameKey = sectionName
        .replace(/ /g, '_')
        .replace('&', 'and')
        .toUpperCase();

    return CONTENT_SECTION_NAMES[sectionNameKey] || sectionName;
};

export const getFilterDocumentTypeByNavState = navState => {
    if (navState === NAV_STATE_TYPES.CHART) {
        return FILTER_DOCUMENT_TYPES_KEY.CHART;
    } else if (navState === NAV_STATE_TYPES.CHECKLIST) {
        return FILTER_DOCUMENT_TYPES_KEY.CHECKLIST;
    } else if (navState === NAV_STATE_TYPES.FORM) {
        return FILTER_DOCUMENT_TYPES_KEY.FORM;
    } else if (navState === NAV_STATE_TYPES.GUIDE_WHITE_PAPER) {
        return FILTER_DOCUMENT_TYPES_KEY.GUIDE_WHITE_PAPER;
    } else if (navState === NAV_STATE_TYPES.INFOGRAPHIC) {
        return FILTER_DOCUMENT_TYPES_KEY.INFOGRAPHIC;
    } else if (navState === NAV_STATE_TYPES.LETTER) {
        return FILTER_DOCUMENT_TYPES_KEY.LETTER;
    } else if (navState === NAV_STATE_TYPES.POLICY) {
        return FILTER_DOCUMENT_TYPES_KEY.POLICY;
    } else if (navState === NAV_STATE_TYPES.TOOLKIT) {
        return FILTER_DOCUMENT_TYPES_KEY.TOOLKIT;
    }
    return '';
};

export const linkTo = (document, history, match, slug = undefined, returnUrl) => {
    const { contentType, documentType, id: contentId } = document;

    let pathContentType = '';
    let isFormsAndLetters = false;

    if (
        contentType === CONTENT_TYPES_MAPPED.DOCUMENT ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.DOCUMENT ||
        !isEmpty(documentType)
    ) {
        if (
            documentType === DOCUMENT_TYPES_MAPPED.FORM ||
            documentType === DOCUMENT_TYPES_MAPPED.LETTER ||
            documentType === DOCUMENT_TYPES_MAPPED.POLICY ||
            documentType === DOCUMENT_TYPES_MAPPED.TOOLKIT
        ) {
            pathContentType = documentType;
            isFormsAndLetters = true;
        } else if (
            documentType === DOCUMENT_TYPES_MAPPED.CHART ||
            documentType === DOCUMENT_TYPES_MAPPED.CHECKLIST ||
            documentType === DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER
        ) {
            pathContentType = documentType;
        } else if (
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.FORM ||
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.LETTER ||
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.POLICY ||
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.TOOLKIT ||
            documentType === DOCUMENT_TYPES_MAPPED.TOOLKIT
        ) {
            pathContentType = DOCUMENT_TYPES_MAPPED[documentType.toUpperCase()];
            isFormsAndLetters = true;
        } else if (
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.CHART ||
            documentType === FILTER_DOCUMENT_TYPES_MAPPED.CHECKLIST
        ) {
            pathContentType = DOCUMENT_TYPES_MAPPED[documentType.toUpperCase()];
        } else if (documentType === FILTER_DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER) {
            pathContentType = DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER;
        }
    } else if (document.originalContentType === CONTENT_TYPES_MAPPED.WEBINARS_ON_DEMAND) {
        pathContentType = document.originalContentType;
    } else {
        pathContentType = contentTypeToContentTypeMapped(contentTypeNameToContentType(contentType));
    }

    let contentPath = getContentPath(pathContentType, contentId, isFormsAndLetters);

    if (
        contentType === CONTENT_TYPES_MAPPED.HELP_CENTER ||
        contentType === CONTENT_TYPES_MAPPED.HELP_CENTER_SEARCH
    ) {
        const { categories = [], contentCategories = [], helpSection, section, id } = document;
        const helpSectionSlug = get(helpSection, 'slug', get(section, 'slug', ''));
        const helpCategorySlug = get(contentCategories[0], 'slug', get(categories[0], 'slug', ''));
        contentPath = `/help-center/${helpSectionSlug}/${helpCategorySlug}/${id.replace(
            'hc_',
            ''
        )}/details`;
    } else if (isEhs(documentType)) {
        const ehsTopic = get(document, 'ehsTopic', get(document, 'ehsTopics.0'));
        const topicId = get(ehsTopic, 'id', '');
        const parentCategoryId = get(ehsTopic, 'parentCategory.id', null);
        const ehsCategoryId = !isEmpty(parentCategoryId)
            ? parentCategoryId
            : get(document, 'contentCategories.0.id', get(document, 'categories.0.id', ''));
        contentPath = `/safety/content-library/${ehsCategoryId}/${topicId}/${contentId}/details`;
    }

    const url = get(match, 'url', '');

    if (returnUrl) {
        return contentPath;
    } else {
        return history.push(contentPath, {
            referrer: url,
            slug,
        });
    }
};

export const formattedVideoUrl = videoUrl => {
    let formattedVideoUrl = videoUrl;
    if (videoUrl.indexOf('brainshark') !== -1 && videoUrl.indexOf('&dm=1') === -1) {
        formattedVideoUrl = `${videoUrl}&dm=1`;
    }
    return formattedVideoUrl;
};

export const getIconForEhsType = (ehsType, props) => {
    switch (ehsType) {
        case 'ehs-quiz':
            return <ContentTypeFormsIcon {...props} />;
        case 'ehs-guide':
            return <ContentTypeGuidesIcon {...props} />;
        case 'ehs-video':
            return <ContentType2MinHrIcon {...props} />;

        default:
            return <ContentTypeChecklistsIcon {...props} />;
    }
};

export const getIconByDocumentType = (contentType, props) => {
    let icon = '';

    if (!contentType) {
        return null;
    }

    if (
        contentType === FILTER_DOCUMENT_TYPES.CHART ||
        contentType === DOCUMENT_TYPES_MAPPED.CHART ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.CHART
    ) {
        icon = <ContentTypeChartsIcon {...props} />;
    } else if (
        FILTER_DOCUMENT_TYPES.CHECKLIST.indexOf(contentType) !== -1 ||
        contentType === DOCUMENT_TYPES_MAPPED.CHECKLIST ||
        contentType === CONTENT_TYPES_MAPPED.HELP_CENTER ||
        contentType === CONTENT_TYPES_MAPPED.HELP_CENTER_SEARCH
    ) {
        icon = <ContentTypeChecklistsIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES.FORM ||
        contentType === DOCUMENT_TYPES_MAPPED.FORM ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.FORM
    ) {
        icon = <ContentTypeFormsIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES.GUIDES ||
        contentType === DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER ||
        contentType === FILTER_DOCUMENT_TYPES.GUIDE_WHITE_PAPER
    ) {
        icon = <ContentTypeGuidesIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES.LETTER ||
        contentType === DOCUMENT_TYPES_MAPPED.LETTER ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.LETTER
    ) {
        icon = <ContentTypeLettersIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES.POLICY ||
        contentType === DOCUMENT_TYPES_MAPPED.POLICY ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.POLICY
    ) {
        icon = <ContentTypePoliciesIcon {...props} />;
    } else if (
        contentType === CONTENT_TYPES_MAPPED.TOOLKITS ||
        contentType === FILTER_DOCUMENT_TYPES.TOOLKIT ||
        contentType === DOCUMENT_TYPES_MAPPED.TOOLKIT ||
        contentType === FILTER_DOCUMENT_TYPES_MAPPED.TOOLKIT
    ) {
        icon = <ContentTypeToolkitsIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES['2MHR'] ||
        contentType === CONTENT_TYPES_MAPPED.TWO_MIN_HR ||
        contentType === CONTENT_TYPES_MAPPED['2MHR']
    ) {
        icon = <ContentType2MinHrIcon {...props} />;
    } else if (
        contentType === FILTER_DOCUMENT_TYPES.LAW ||
        contentType === CONTENT_TYPES.LAW ||
        contentType === CONTENT_TYPES_MAPPED.LAW
    ) {
        icon = <ContentTypeLawsIcon {...props} />;
    } else if (
        contentType === CONTENT_NAMES.QUESTION_AND_ANSWER ||
        contentType === CONTENT_TYPES.QUESTION_AND_ANSWER ||
        contentType === CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER ||
        contentType === DOCUMENTS.CONTENT_TYPES['Q&A']
    ) {
        icon = <ContentTypeQAIcon {...props} />;
    } else if (
        contentType === CONTENT_NAMES.WEBINARS ||
        contentType === CONTENT_TYPES_MAPPED.WEBINARS ||
        contentType === CONTENT_TYPES_MAPPED.WEBINARS_DEMAND
    ) {
        icon = <ContentTypeWebinarsIcon {...props} />;
    } else if (
        FILTER_DOCUMENT_TYPES.LAW_ALERT.indexOf(contentType) !== -1 ||
        contentType === CONTENT_TYPES.LAW_ALERT ||
        contentType === CONTENT_TYPES_MAPPED.LAW_ALERT
    ) {
        icon = <ContentTypeLawAlertsIcon {...props} />;
    } else if (contentType.indexOf('ehs-') !== -1) {
        icon = getIconForEhsType(contentType, props);
    } else {
        icon = null;
    }

    return icon;
};

export const isSectionWithNewFilters = url => {
    return DOCUMENTS.SECTIONS_NEW_FILTERS.indexOf(url) !== -1;
};

export const getValueFromSubArray = (items, key, keyIndex, valueIndex) => {
    const subArray = items.filter(item => item[keyIndex] === key);

    if (subArray.length && subArray[0].length) {
        return subArray[0][valueIndex];
    }
    return null;
};

export const getCategorySectionByUrl = (match, categories) => {
    let url = get(match, 'params.categoryRoute', '');

    if (isEmpty(url)) {
        url = get(match, 'params.state', '');
    }

    const splitUrl = url.split('-');
    const category = !isEmpty(categories)
        ? categories.filter(option => {
              return option.name.toLowerCase().includes(splitUrl[0].toLowerCase(0));
          })
        : [];

    return !isEmpty(category) ? category[0] : {};
};

export const onClickExpanded = (expanded, expandedGroup, groupId) => {
    const itemPosition = expandedGroup.indexOf(groupId);

    if (itemPosition !== -1 && !expanded) {
        expandedGroup.splice(itemPosition, 1);
    } else if (itemPosition === -1 && expanded) {
        expandedGroup.push(groupId);
    }

    return expandedGroup;
};

export const formattedHelpCenterBody = (body, userPermissionsOriginal) => {
    if (isEmpty(body)) {
        return '';
    }

    // Get value for <permission> tag, to filter text content per user logged.
    const pattern = RegExp(/<permission="(.*?)">([\w\s]+.)<\/permission>/gi);
    const patternPermission = RegExp(/"(.*?)"/gi);
    const matches = body.match(pattern);
    const userAssistancePermission = userPermissionsOriginal.filter(
        ({ permissionKey = '' }) => permissionKey.indexOf(USER_ASSISTANCE) !== -1
    );

    !isEmpty(matches) &&
        matches.forEach(text => {
            const matchesPermissionList = text.match(patternPermission);
            !isEmpty(matchesPermissionList) &&
                matchesPermissionList.forEach(permissionList => {
                    const permissionArray = permissionList.replace(/\"/g, '').split(',');
                    let hideParagraph = true;
                    const x = [];
                    !isEmpty(permissionArray) &&
                        permissionArray.forEach(permission => {
                            x.push(
                                userAssistancePermission.some(e => e.permissionKey === permission)
                            );
                        });
                    hideParagraph = !get(x.filter(Boolean), '0', false);
                    if (hideParagraph) {
                        body = body.replace(text, '');
                    }
                });
        });

    return body;
};

export const formattedHelpCenterData = contentEntry => {
    const { category, detail, section } = contentEntry;
    const { content, contentType } = detail;
    let updatedDetail = detail;

    switch (contentType) {
        case USER_ASSISTANCE_VIDEO_LINK_AUTHORID:
            updatedDetail = {
                ...detail,
                content: '',
                videoUrl: content,
            };
            break;
        case USER_ASSISTANCE_PDF_LINK_AUTHORID:
            updatedDetail = {
                ...detail,
                content: '',
                file: {
                    url: content.replace('http:', '').replace('https:', ''),
                },
            };
            break;

        default:
            break;
    }

    return {
        category,
        detail: updatedDetail,
        section,
    };
};

export const showNewHelpCenter = location => {
    const isNewHelpCenter = hasFeatureFlag(location, HELP_CENTER_FLAG);
    return isNewHelpCenter ? `/?${HELP_CENTER_FLAG}` : '';
};

export const isEhs = documentType => {
    return documentType ? documentType.toLowerCase().indexOf('ehs') !== -1 : false;
};

export const showBanner = location => {
    return hasFeatureFlag(location, SHOW_BANNER_EHS_FLAG);
};

export const getInfoDocumentType = (contentTypes, contentType, documentType) => {
    const infoContentType =
        contentTypes &&
        Array.isArray(contentTypes) &&
        contentTypes.find(elem => elem.name === contentType || elem.japiEndpoint === contentType);
    const allDocumentTypes = get(infoContentType, 'documentTypes', false);
    return allDocumentTypes
        ? allDocumentTypes.find(
              elem => elem.name === documentType || elem.mappedName === documentType
          )
        : false;
};

export const buildSlug = name => {
    return name
        .replace(/[^0-9a-zA-Z]/g, ' ')
        .replace(/ +(?= )/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
};

export const getNameByContentAndDocumentType = ({
    contentType,
    contentTypes,
    documentType,
    documentTypes,
}) => {
    if (documentType) {
        const documentTypeObject = documentTypes.find(obj => obj.mappedName === documentType);
        const documentTypeName = get(documentTypeObject, 'name', '');

        return documentTypeName || _startCase(documentType);
    } else {
        const contentTypeObject = contentTypes.find(obj => obj.japiEndpoint === contentType);
        const contentTypeName = get(contentTypeObject, 'name', '');

        return contentTypeName || _startCase(contentType);
    }
};

export const scrollToPage = pageNumber => {
    const pageElements = document.querySelectorAll('.page');
    const pageToScroll = pageElements[pageNumber - 1];
    const documentContainer = document.querySelector('.pdfContainerDocumentToScroll');

    if (pageToScroll && documentContainer) {
        documentContainer.scrollTop = pageToScroll.offsetTop;
    }
};

export const getCurrentPageWhileScrolling = scrollContainer => {
    const scrollPosition = scrollContainer.scrollTop;
    const firstPageElement = document.querySelector('.page');
    const pageHeight = firstPageElement ? firstPageElement.offsetHeight - 10 : 0;
    const currentPage = Math.floor(scrollPosition / pageHeight) + 1;

    return currentPage;
};

export const getWidthToPdfViewerMobile = () => {
    const element1 = document.querySelector('.documentActionContainer');
    let actionsHeightInVh = 0;
    let paginationHeightInVh = 0;

    if (element1) {
        const actionsHeightInPixels = element1.offsetHeight;
        actionsHeightInVh = (actionsHeightInPixels / window.innerHeight) * 100;
    }

    const element2 = document.querySelector('.documentPaginationContainer');
    if (element2) {
        const paginationHeightInPixels = element2.offsetHeight;
        paginationHeightInVh = (paginationHeightInPixels / window.innerHeight) * 100;
    }

    const heightInPixels = window.innerHeight * 0.9;
    const heightInVh = (heightInPixels / window.innerHeight) * 100;

    return heightInVh - actionsHeightInVh - paginationHeightInVh;
};

export const handleThumbsModal = async ({
    value,
    currentUrl,
    closeModal,
    fetchFeedbackOptions,
    openModal,
    showFeedbackModal,
    toggleLabels,
    userChoice,
    handleOnClick,
    handleSubmitFeedback,
    setState,
}) => {
    const shouldOpenModal = SECTIONS_WITH_FEEDBACK_MODAL.some(section =>
        currentUrl.includes(section)
    );

    const isActive = value === toggleLabels.active;
    const options = fetchFeedbackOptions
        ? await fetchFeedbackOptions({ type: isActive ? 'up' : 'down' })
        : [];

    let updateUserChoice;
    if (value === toggleLabels.active) {
        updateUserChoice = userChoice === toggleLabels.active ? null : toggleLabels.active;
    }
    if (value === toggleLabels.inactive) {
        updateUserChoice = userChoice === toggleLabels.inactive ? null : toggleLabels.inactive;
    }

    if (!updateUserChoice || !showFeedbackModal || !shouldOpenModal) {
        return handleOnClick(value, updateUserChoice);
    }

    const ThumbsFeedbackProps = {
        options: !isEmpty(options)
            ? options.map(item => {
                  return { id: item.id, label: item.name };
              })
            : [],
        textBoxLabel: isActive ? THUMBS_UP_OPTIONS.TEXBOX_LABEL : THUMBS_DOWN_OPTIONS.TEXBOX_LABEL,
        handleExternalCheckboxChange: value => {
            setState({ feedbackUserChoiceOptions: value });
        },
        handleExternalTextChange: value => {
            setState({
                feedbackUserChoiceComments: value,
            });
        },
        actions: [
            {
                text: THUMBS_DOWN_OPTIONS.BUTTON_CANCEL,
                color: 'secondary',
                padding: '0.75rem 1.5rem 0.688rem 1.5rem',
                style: {
                    fontSize: '0.75rem ',
                    fontFamily: 'Favorit',
                    borderRadius: '2px',
                    minWidth: 'auto',
                    width: '50%',
                },
                onClick() {
                    closeModal(MODALS.COMMON);
                },
            },
            {
                text: THUMBS_DOWN_OPTIONS.BUTTON_FEEDBACK,
                color: 'primary',
                padding: '0.75rem 1.5rem 0.688rem 1.5rem',
                style: {
                    fontSize: '0.75rem ',
                    fontFamily: 'Favorit',
                    borderRadius: '2px',
                    minWidth: 'auto',
                    width: '50%',
                },
                onClick: () => {
                    handleSubmitFeedback(value, updateUserChoice);
                    closeModal(MODALS.COMMON);
                },
            },
        ],
    };

    return openModal(MODALS.COMMON, {
        hasCloseIcon: false,
        isComponent: true,
        hideActions: true,
        hideIcon: true,
        description: <ThumbsFeedback {...ThumbsFeedbackProps} />,
        title: '',
        ModalProps: {
            ContentProps: {
                style: {
                    maxWidth: '520px',
                    margin: '1rem',
                    padding: '2.5rem',
                    borderRadius: '0.5rem',
                    backgroundColor: DEFAULT_COLORS.WHITE,
                },
            },
        },
        modalProps: {
            bodyProps: {
                padding: '0',
            },
            footerProps: {
                padding: '0',
                justifyContent: 'flex-start !important',
            },
        },
    });
};

export const handleExpandedImageClick = imageOptions => {
    const { closeModal, openModal, src } = imageOptions;

    openModal(MODALS.IMAGE_PREVIEW_MODAL, {
        hasCloseIcon: true,
        isComponent: true,
        hideActions: true,
        hideIcon: true,
        description: <img style={{ width: '100%' }} src={src} alt="expandedImage" />,
        title: '',
        ModalProps: {
            ContentProps: {
                style: {
                    maxWidth: '900px',
                    margin: '0',
                    padding: '0',
                    borderRadius: '0.5rem',
                    backgroundColor: DEFAULT_COLORS.WHITE,
                },
            },
            onBackdropClick: () => {
                closeModal(MODALS.IMAGE_PREVIEW_MODAL);
            },
        },
    });
};

export const addClickEventToExpandImages = imageOptions => {
    const { classSelector, closeModal, docRef, openModal } = imageOptions;
    const images = docRef?.current?.querySelectorAll(`img.${classSelector}`);

    if (images && images.length > 0) {
        images.forEach(img => {
            img.onclick = () => handleExpandedImageClick({ src: img.src, closeModal, openModal });
        });
    }
};

export const filterFavorites = (userFavorites, hasCompliancePermission) => {
    if (hasCompliancePermission) {
        return userFavorites;
    }
    return userFavorites.filter(
        item => !COMPLIANCE_SECTIONS_URL.some(section => item.url.includes(section))
    );
};
